import React from "react";
import logo from "../../assets/images/hanna1.png";
import introVideo from "./../../assets/video/VIRT platform intro offer.mp4";
import ReducePain from "./ReducePain";
import LandingComponent2 from "./LandingComponent2";
import LandingComponent3 from "./LandingComponent3";
import LandingComponent4 from "./LandingComponent4";
import WellnessVideoLibrary from "../wellNessVideo/WellnessVideo";

const LandingPage = ({ offer, paymentHanded }) => {
  return (
    <div>
      <div
        className="landing-container"
        style={{
          position: "relative",
          color: "grey", 
          textAlign: "center", 
        }}
      >
        
        <img
          src={logo}
          alt="Logo"
          style={{
            position: "absolute",
            top: "10px",
            left: "50%",
            transform: "translateX(-50%)", // Center the logo horizontally and vertically
            maxWidth: "100%", // Adjust logo size as needed
            maxHeight: "140px", 
          }}
        />
        </div>
        <div className="container px-3 px-md-0">
          <div className="row d-flex justify-content-center align-items-center pt-5 ">
            <div className="col-lg-11 col-xl-9 z-10 pt-4">
              <h1 className="mt-5 pt-5 mb-3" style={{ color: "black", fontSize: "50px" }}>
              Exercises to prevent & reduce pain.
              </h1>

              <p className="step_boldsubtext" style={{ color: "#a3aed0" }}>
                Exercises and resources to help you feel better for a more
                enjoyable life.
              </p>
            </div>
          </div>
        </div>
          <div className="container pt-3 z-10">
            <div className="row d-flex align-items-stretch justify-content-center">
              <div className="col-lg-11 col-xl-9 mb_24">
                <div className="video_play_area ">
                  <div className="container mb-4 mb-xl-5 pb-xl-5 pt-4 pt-xl-5 ">
                    <div className="row align-items-center">
                
                      <div className="col-sm-12">
                        <div ></div>
                        <video
                          controlsList="nodownload"
                          width="100%"
                          height="500px"
                          controls
                        >
                
                          <source src={introVideo} type="video/mp4" />
                        </video>
                      </div>
                    </div>
                  </div>
                
                </div>
              </div>
            </div>
          </div>


      <div className="container mt-4">
        <ReducePain paymentHanded={paymentHanded} offer={offer} />
      </div>
      <LandingComponent2 paymentHanded={paymentHanded} offer={offer} />
      <LandingComponent3 paymentHanded={paymentHanded} offer={offer} />

      <WellnessVideoLibrary
        showHello={true}
        paymentHanded={paymentHanded}
        offer={offer}
      />

      <LandingComponent4 paymentHanded={paymentHanded} offer={offer} />
    </div>
  );
};

export default LandingPage;
