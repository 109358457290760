import React, { useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import queryString from "query-string";
import styles from "./Thanks.module.css"; // Import the CSS module
import logo from "./images/logo.png";
import { useSearchParams } from "react-router-dom";
const Success = () => {
  const { session_id } = queryString.parse(window.location.search);
  const { updateSubscription } = useApiAxios();
  const [userType, seTUserType] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (session_id) {
      updateSubscription(session_id);
    }
    seTUserType(searchParams.get("for"));
  }, []);

  return userType ? (
    userType === "patient" ? (
      <div className={styles.overFlow}>
        <div className={styles.mainDiv}>
          <meta charSet="utf-8" />
          <title>Thank You</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <link rel="shortcut icon" href="path/to/assets/images/favicon.png" />

          {/* Thank You */}
          <div className={styles.logo}>
            <img src={logo} alt="Logo" />
          </div>
          <div className="d-flex justify-content-center align-items-center vh-100 w-100">
            <div className="text-center align-items-center w-100 m-auto">
              <div className="row">
                <div className="col-12 text-center">
                  <div className={`mb-5 fw-bold ${styles.font20}`}>
                    PAYMENT WAS SUCCESSFUL
                  </div>
                  <div
                    className={`${styles.text_brittany} ${styles.thank_you_text}`}
                  >
                    Thank You
                  </div>
                  <div className={`${styles.font26} mb-5`}>FOR SIGNING UP</div>
                  <div className={styles.font24}>
                    YOU WILL SOON RECEIVE AN EMAIL WITH YOUR LOGIN INFORMATION.
                    PLEASE KEEP
                    <br />
                    AN EYE ON YOUR INBOX, INCLUDING YOUR SPAM OR JUNK FOLDERS,
                    JUST IN CASE.
                  </div>
                  <div className="pt-5 mb-3">
                    <a href="/login" className={styles.start_btn}>
                      START MY JOURNEY
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className={styles.overFlow}>
        <div className={styles.mainDiv}>
          <title>Thank You</title>
          <div className={styles.logo}>
            <img src={logo} alt="Logo" />
          </div>
          <div className="d-flex justify-content-center align-items-center vh-100 w-100">
            <div className="text-center align-items-center w-100 m-auto">
              <div className="row">
                <div className="col-12 text-center">
                  <div className={`mb-5 fw-bold ${styles.font20}`}>
                    PAYMENT WAS SUCCESSFUL
                  </div>
                  <div
                    className={` ${styles.text_brittany} ${styles.thank_you_text}`}
                  >
                    Thank You
                  </div>
                  <div className={styles.font26}>FOR SIGNING UP</div>
                  <div
                    className={`my-5 pt-5 ${styles.text_brittany} ${styles.font54}`}
                  >
                    We are excited to embark <br /> <br /> <br />on this journey with you
                  </div>
                  <div className={`mb-5 ${styles.font24}`}>
                    TOWARDS A HEALTHIER BUSINESS
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  ) : (
    <></>
  );
};

export default Success;
