import React from "react";
import { Elements } from "@stripe/react-stripe-js";

import { loadStripe } from "@stripe/stripe-js";
import PaymentPageResubscriptionCompany from "./PaymentPageResubscriptionCompany";
const SubscriptionCompany = () => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  return (
    <Elements stripe={stripePromise}>
      <PaymentPageResubscriptionCompany />
    </Elements>
  );
};

export default SubscriptionCompany;
