import React from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";


const DeleteSubscriptionModel = ({ show, handleClose, data, deleteFunction }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      keyboard={false}
      className="modal fade success-popup add-company"
    >
      <div className="modal-body text-start">
        <p>Are you sure you want to cancel the subscription?</p>

        <div className="modal-footer justify-content-center">
          <Link
            className="btn addsubmit-btn green-btn mb-3 px-3 px-sm-5"
            onClick={() => deleteFunction(data)}
          >
            YES
          </Link>
          <Link
            onClick={handleClose}
            className="btn addsubmit-btn grey-btn me-3 mb-3 px-3 px-sm-5"
          >
            NO
          </Link>
        </div>
      </div>
    </Modal>
  );
};
export default DeleteSubscriptionModel;
