import React, { useState } from "react";
import pain from "./../json/pain.json";
import painLevelIcon from "./../../assets/images/painscale.jpg";
import { Link } from "react-router-dom";

const SelectPainLabel = () => {
  const [painLevelNumber, setPainLabelNumber] = useState(null);
  return (
    <div>
      <>
        <div className="container banner_area px-3 px-md-0">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-xl-10 col-lg-10 z-10">
             
              <h1 className="step_title text-center mb-3">
                Understanding Your Symptoms
              </h1>
             
            </div>
          </div>
        </div>
        {/* Banner end */}
        {/* VIRT Wellness Video Library */}
        <div className="">
          <div className="container mb-4 mb-xl-5 pt-3 z-10">
            <div className="row d-flex align-items-stretch justify-content-center">
              <div className="col-xl-10 col-lg-10 mb_24">
                <div className="step_area min-h-360">
                  <h4 className="question-title pb-3 ">
                    What is your pain level?
                    <span className="text-danger">*</span>
                  </h4>
                  <div className="col-12 pt-2 pb-4">
                    <img
                      src={painLevelIcon}
                      alt="Pain Scale"
                      className="w-100"
                    />
                  </div>
                  <div className="col-md-12 custom-painscale-conatiner">
                    <div className="row">
                      <div className="col-lg-2 col-md-12 pain-level">
                        No Pain
                      </div>
                      <div className="col-lg-8 col-md-12">
                        <div className="row text-center">
                          {pain.map(({ painLevel }) => (
                            <div className="col-lg custom-radiocontainer">
                              <div className="custom-radio">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="inlineRadioOptions"
                                  id={`inlineRadio${painLevel}`}
                                  defaultValue="option1"
                                  onChange={() => setPainLabelNumber(painLevel)}
                                />
                              </div>
                              <div className="custom-radio-level px-1 py-1">
                                <label
                                  className="form-check-label"
                                  htmlFor={`inlineRadio${painLevel}`}
                                >
                                  {painLevel}
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-12 pain-level">
                        Worst Pain Possible
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex step-footer">
                  <Link
                    to="/question/painQuestion"
                    className="step_btnoutline d-inline-block me-auto"
                  >
                    Previous
                  </Link>
                  {painLevelNumber || painLevelNumber === 0? (
                    <Link
                      to={`/question/select/body/part/${painLevelNumber}`}
                      className="step_btn d-inline-block ms-auto"
                    >
                      Next
                    </Link>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default SelectPainLabel;
