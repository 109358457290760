import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
const Step1 = () => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (option) => {
    console.log(option);
    setSelectedOption(option);
  };

  const isNextButtonDisabled = !selectedOption;

  return (
    <>
      <div className="container banner_area px-3 px-md-0">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-xl-10 col-lg-10 z-10 pt-4">
            <h1 className="step_title text-center mb-3">Let's Get Started!</h1>
            <p className="step_boldsubtext text-left">
              We're here to assist and ensure you receive the best help possible. Could you please take a moment to answer a few questions? This will help us understand your needs better and guide you to the right resources.
            </p>
          </div>
        </div>
      </div>
      {/* Banner end */}
      {/* VIRT Wellness Video Library */}
      <div className="">
        <div className="container mb-4 mb-xl-5 pt-3 ">
          <div className="row d-flex align-items-stretch justify-content-center">
            <div className="col-xl-10 col-lg-10 my-5 mb_24">
              <div className="step_area">
                <h4 className="question-title pb-3">
                  Are you currently unable to perform important activities or
                  experiencing difficulty due to a current problem or pain? E.g., picking                  
                  items off the floor, lifting or carrying weights, bending forward.
                </h4>
                <div className="form-check py-2 pe-2">
                  <input
                    className="form-check-input mr-1"
                    type="radio"
                    name="emp-1"
                    defaultValue=""
                    id="remember1"
                    onChange={() => handleOptionChange("Yes")}
                  />
                  <label
                    className="form-check-label font14"
                    htmlFor="remember1"
                  >
                    Yes
                  </label>
                </div>
                <div className="form-check py-2 pe-2">
                  <input
                    className="form-check-input mr-1"
                    type="radio"
                    defaultChecked=""
                    name="emp-1"
                    defaultValue=""
                    id="remember2"
                    onChange={() => handleOptionChange("No")}
                  />
                  <label
                    className="form-check-label font14"
                    htmlFor="remember2"
                  >
                    No
                  </label>
                </div>
              </div>
              <div className="d-flex step-footer">
                <Link
                  to={
                    !isNextButtonDisabled
                      ? selectedOption === "Yes"
                        ? "/question/step/y"
                        : "/question/painQuestion"
                      : ""
                  }
                  className={`step_btn d-inline-block ms-auto ${
                    isNextButtonDisabled ? "disabled" : ""
                  }`}
                >
                  Next step
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step1;
