import React from "react";
import landingImage4 from "../../assets/images/virt3.png";

const LandingComponent4 = ({ offer, paymentHanded }) => {
  return (
    <div
      className="container mt-5"
      style={{ backgroundColor: "#D0EBD4", padding: "20px" }}
    >
      <div className="row align-items-center">
        <div className="col-md-6 mb-4 mb-md-0">
          <img
            src={landingImage4}
            className="img-fluid rounded shadow-lg"
            alt="Placeholder"
          />
        </div>
        <div className="col-md-6 d-flex align-items-center justify-content-center">
          <div>
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <h1 className="blue_text fw-bold mb-3">
                    This Program Is For You If...
                  </h1>
                </div>
                <div className="col-sm-12">
                  <ul className="why-us">
                    <li>
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                      You are tired of feeling tension and stiffness.
                    </li>
                    <li>
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                      You want a practical, time-efficient approach to exercise.
                    </li>
                    <li>
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                      You want to learn how to self manage and take control of your health
                    </li>
                    <li>
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                      You want to improve your mobility and avoid pain and
                      injuries.
                    </li>
                    <li>
                      <button
                        onClick={() =>
                          paymentHanded(
                            offer.createdFor === "physical-therapists"
                              ? offer?.year?.stripePriceId
                              : offer?.year?.stripePlanId
                          )
                        }
                        className="btn virt_btn w-auto"
                        style={{
                          color: "white",
                          backgroundColor: "#129252",
                          padding: "10px 20px",
                          height: "50px",
                          borderRadius: "10px",
                          border: "none",
                        }}
                      >
                        YES, I WANT IN !
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingComponent4;
