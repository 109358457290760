import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Testimonial from "../testimonial/Testimonial";
import WellnessVideoLibrary from "../wellNessVideo/WellnessVideo";
import { Context } from "../../context/Context";
import ImageComponent from "../media/ImageComponent";

const Home = () => {
  const { content } = useContext(Context);

  return (
    <>
      <div className="container-fluid banner_area">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-md-5 order-2 order-md-1">
            <h1 className="banner-title mt-4 mt-md-0">{content?.t1}</h1>
            <h6 className="banner-subtitle">{content?.t2}</h6>
            <Link to="/contact" className="book1-btn">
              BOOK A DEMO
            </Link>
          </div>
          <div className="col-md-7 order-1 order-md-2 text-end">
            <ImageComponent
              className="img-fluid banner-img"
              src={`${content.img1}`}
              title="Wellness video platform to prevent & reduce pain"
            />
          </div>
        </div>
      </div>
      <div className="container my-4 my-xl-5 pt-4 pt-xl-5">
        <div className="row">
          <div className="col-sm-6">
            <div className="jurny_area">
              <ImageComponent src={`${content.img2}`} />
              <h3>{content?.t3}</h3>
              <p>{content?.t5}</p>
              <Link to="/employers" className="virt_btn green">
                Hanna Health for Businesses
              </Link>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="jurny_area">
              <ImageComponent src={`${content.img3}`} />

              <h3>{content?.t4}</h3>
              <p>{content?.t6}</p>
              <Link to="/physical-therapists" className="virt_btn purpel">
               Hanna Health for Physical Therapists
              </Link>
            </div>
          </div>
        </div>
      </div>
      <>
        <WellnessVideoLibrary />
        <Testimonial />
      </>

      <div className="container mb-4 mb-xl-5 pt-4 pb-4 pb-xl-5">
        <div className="row py-4 py-xl-5 justify-content-center">
          <div className="col-xl-9">
            <h1 className="home_title text-center mb-3">{content?.t7}</h1>
            <p className="home_subtext text-center">{content?.t8}</p>
            <div className="row">
              <div className="col-md-6 text-center">
                <Link to="/employers" className="virt_btn green">
                Hanna Health for Businesses
                </Link>
              </div>
              <div className="col-md-6 text-center">
                <Link to="/physical-therapists" className="virt_btn purpel">
                Hanna Health for Physical Therapists
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
