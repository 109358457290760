import React, { useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-phone-number-input/style.css";
import "react-datepicker/dist/react-datepicker.css";
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = () => {
  const { contactApi } = useApiAxios();
  const [captchaToken, setCaptchaToken] = useState("");

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      query: "",
      organization: "",
      inquiryAbout: "",
      iAmAsA: "individual",
    },

    validationSchema: Yup.object({
      firstName: Yup.string()
        .required("First Name is required")
        .min(2, "First Name should be at least 2 characters"),

      lastName: Yup.string()
        .required("Last Name is required")
        .min(2, "Last Name should be at least 2 characters"),

      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),

      phone: Yup.string()
        .matches(/^\+?\d{1,10}$/, "Invalid phone number")
        .max(10, "Phone number should not exceed 10 digits")
        .min(10, "Invalid phone number")
        .required("Phone number is required"),

      query: Yup.string()
        .matches(
          /^[a-zA-Z0-9\s\S]*$/,
          "Query should only contain alphabets, numbers, and special characters"
        )
        .max(256, "Query should not exceed 256 characters")
        .required("Query is required"),
      organization: Yup.string().max(
        50,
        "Organization should not exceed 50 words"
      ),
      inquiryAbout: Yup.string().required("Please select an option"), 
      iAmAsA: Yup.string(), 
    }),
    onSubmit: (values) => {
      if (captchaToken) {
        values.captchaToken = captchaToken;
        contactApi(values);
      } else {
        alert("Please verify that you are not a robot.");
      }
    },
  });
  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

 
  return (
    <>
      <div className="container my-4 my-xl-5 pt-5 pt-xl-5">
        <div className="row pt-5">
          <h1 className="home_title text-center">Contact Us</h1>
        </div>
      </div>
      <div className="container mb-4 mb-xl-5 pb-4 pb-xl-5">
        <div className="row pb-4 pb-xl-5 justify-content-center contact-form">
          <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3 row">
                <label for="" className="col-sm-3 col-form-label">
                  I am a <span></span>
                </label>
                <div className="col-sm-9 pt-2">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input "
                      type="radio"
                      defaultChecked
                      value={"individual"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="iAmAsA"
                      id="inlineRadio1"
                    />

                    <label className="form-check-label " htmlFor="inlineRadio1">
                      Individual
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      value={"employers"}
                      id="inlineRadio2"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="iAmAsA"
                    />
                    <label className="form-check-label " htmlFor="inlineRadio2">
                      Employer
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      value={"physical-therapists"}
                      id="inlineRadio3"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="iAmAsA"
                    />
                    <label className="form-check-label " htmlFor="inlineRadio3">
                      Physical Therapist
                    </label>
                  </div>

                  
                </div>
              </div>
              <div className="mb-3 row">
                <label for="" className="col-sm-3 col-form-label">
                  First Name<span>*</span>
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                  />
                  {formik.touched.firstName && formik.errors.firstName && (
                    <small className="text-danger">
                      {formik.errors.firstName}
                    </small>
                  )}
                </div>
              </div>
              <div className="mb-3 row">
                <label htmlFor="lastName" className="col-sm-3 col-form-label">
                  Last Name<span>*</span>
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName}
                  />
                  {formik.touched.lastName && formik.errors.lastName && (
                    <small className="text-danger">
                      {formik.errors.lastName}
                    </small>
                  )}
                </div>
              </div>
              <div className="mb-3 row">
                <label for="" className="col-sm-3 col-form-label">
                  Email<span>*</span>
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    id="email"
                    name="email"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <small className="text-danger">{formik.errors.email}</small>
                  )}
                </div>
              </div>
              <div className="mb-3 row">
                <label htmlFor="phone" className="col-sm-3 col-form-label">
                  Phone Number<span>*</span>
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    className="form-control"
                    onChange={(e) => {
                      // Filter out non-numeric characters
                      const phoneNumber = e.target.value.replace(/\D/g, "");
                      // Limit to 10 digits
                      if (phoneNumber.length <= 10) {
                        formik.setFieldValue("phone", phoneNumber);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <small className="text-danger">{formik.errors.phone}</small>
                  )}
                </div>
              </div>
              <div className="mb-3 row">
                <label
                  htmlFor="inquiryAbout"
                  className="col-sm-3 col-form-label"
                >
                  Inquiry About<span>*</span>
                </label>
                <div className="col-sm-9">
                  <select
                    id="inquiryAbout"
                    name="inquiryAbout"
                    className="form-select"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.inquiryAbout}
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    <option
                      style={{ color: "black" }}
                      value="Get started with Hanna Health"
                    >
                      Get started with Hanna Health
                    </option>
                    <option
                      style={{ color: "black" }}
                      value="Get more information about Hanna Health "
                    >
                      Get more information about Hanna Health
                    </option>
                    <option
                      style={{ color: "black" }}
                      value="White Paper"
                    >
                      White Paper
                    </option>
                    <option
                      style={{ color: "black" }}
                      value="Request a demo of Hanna Health "
                    >
                      Request a demo of Hanna Health
                    </option>
                    <option
                      style={{ color: "black" }}
                      value="Get pricing for Hanna Health"
                    >
                      Get pricing for Hanna Health
                    </option>
                    <option
                      style={{ color: "black" }}
                      value="Privacy and Security"
                    >
                      Privacy and Security
                    </option>
                    <option style={{ color: "black" }} value="Other questions">
                      Other questions
                    </option>
                  </select>
                  {formik.touched.inquiryAbout &&
                    formik.errors.inquiryAbout && (
                      <small className="text-danger">
                        {formik.errors.inquiryAbout}
                      </small>
                    )}
                </div>
              </div>
              <div className="mb-3 row">
                <label
                  htmlFor="organization"
                  className="col-sm-3 col-form-label"
                >
                  Organization
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    id="organization"
                    name="organization"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.organization}
                  />
                  {formik.touched.organization &&
                    formik.errors.organization && (
                      <small className="text-danger">
                        {formik.errors.organization}
                      </small>
                    )}
                </div>
              </div>
              <div className="mb-3 row">
                <label htmlFor="query" className="col-sm-3 col-form-label">
                  Query<span>*</span>
                </label>
                <div className="col-sm-9 ">
                  <textarea
                    id="query"
                    name="query"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.query}
                    maxLength={256}
                  />
                  <p className="text-end mb-0">
                    <small className="text-end">
                      {formik.values.query.length}/256
                    </small>
                  </p>

                  {formik.touched.query && formik.errors.query && (
                    <small className="text-danger">{formik.errors.query}</small>
                  )}
                </div>
              </div>
              <div className="mb-3 row ">
                <label for="" className="col-sm-3 col-form-label"></label>
                <div className="col-sm-9">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA}
                    onChange={handleCaptchaChange}
                  />
                </div>
              </div>

              <div className="mb-3 row mt-5">
                <label for="" className="col-sm-3 col-form-label"></label>
                <div className="col-sm-9 ">
                  <button type="submit" className=" btn book-btn mb-3">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
