import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import typeOne from "../../assets/images/type1.svg";
import typeTwo from "../../assets/images/type2.svg";
import typeThree from "../../assets/images/type3.svg";
import typeFour from "../../assets/images/type4.svg";
import useApiAxios from "../../api/useApiAxios";

const Goal = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const navigate = useNavigate();
  const { bodyName, painLabel } = useParams();
  const { createDynamicUserQuestionApi } = useApiAxios();

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    
  };

  const createDynamicUserQuestionHandler = async () => {
    if (selectedOption === "Decrease pain" && bodyName && painLabel) {
      const data = await createDynamicUserQuestionApi({
        goal: "Decrease pain",
        bodyName,
        painLabel,
      });
      navigate(`/question/d-pain/${data.data.data._id}`);
    } else {
      const data = await createDynamicUserQuestionApi({
        goal: selectedOption,
        bodyName: "",
        painLabel: "",
      });
      navigate(`/question/d-pain/${data.data.data._id}`);
    }
  };

  const isNextButtonDisabled = !selectedOption;
  return (
    <>
     
      <div className="">
        <div className="container mb-4 mb-xl-5  z-10 pt-5 mt-5">
          <div className="row d-flex align-items-stretch justify-content-center">
            <div className="col-xl-10 col-lg-10 mb_24">
              <div className="step_area">
                <h4 className="question-title fw-bold mb-2">
                  What is your goal?
                </h4>
                <p className="question-subtitle pb-3">
                  Please select the service you are interested in.
                </p>
                <div className="row">
                  {painLabel && bodyName ? (
                    <div className="col-md-6">
                      <div className="ratings">
                        <label className="rating-item">
                          <div>
                            <input
                              type="radio"
                              name="rating-option"
                              defaultValue="true"
                              onChange={() =>
                                handleOptionChange("Decrease pain")
                              }
                            />
                            <div className="rating-box">
                              <span className="rating-score">
                                {" "}
                                <img src={typeOne} alt="" />{" "}
                                <span>Decrease pain</span>{" "}
                              </span>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="col-md-6">
                    <div className="ratings">
                      <label className="rating-item">
                        <input
                          type="radio"
                          name="rating-option"
                          defaultChecked=""
                          onChange={() =>
                            handleOptionChange(
                              "Learn how to set up my desk and improve my posture"
                            )
                          }
                        />
                        <div className="rating-box">
                          <span className="rating-score">
                            <img src={typeTwo} />
                            <span>
                            Education and Prevention
                            </span>{" "}
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="ratings">
                      <label className="rating-item">
                        <input
                          type="radio"
                          name="rating-option"
                          onChange={() =>
                            handleOptionChange(
                              "Reduce tightness and move better"
                            )
                          }
                        />
                        <div className="rating-box">
                          <span className="rating-score">
                            <img src={typeThree} />
                            <span>Reduce tightness and move better</span>{" "}
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="ratings">
                      <label className="rating-item">
                        <input
                          type="radio"
                          name="rating-option"
                          onChange={() => handleOptionChange("Get stronger")}
                        />
                        <div className="rating-box">
                          <span className="rating-score">
                            <img src={typeFour} />
                            <span>Get stronger</span>{" "}
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex step-footer">
                <Link
                  to="/question"
                  className="step_btnoutline d-inline-block me-auto"
                >
                  Previous step
                </Link>
                <Link
                  onClick={createDynamicUserQuestionHandler}
                  className={`step_btn d-inline-block ms-auto ${
                    isNextButtonDisabled ? "disabled" : ""
                  }`}
                >
                  Next step
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Goal;
