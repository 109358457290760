import React, { useContext, useEffect, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import useApiAxios from "../../api/useApiAxios";
import { Context } from "../../context/Context";
const PaymentPageResubscription = () => {
  const { userData } = useContext(Context);
  const [offer, setOffer] = useState({});
  const { getOfferById } = useApiAxios();
  const fun = async () => {
    const data = await getOfferById(userData.offer);
    if (!data.error) {
      setOffer(data.data.data);
    }
  };
  useEffect(() => {
    fun();
  }, []);
  const stripe = useStripe();
  const elements = useElements();
  const { reSubscriptionApi } = useApiAxios();
  const [loading, setLoading] = useState(false);

  return (
    <div className="login-container m-auto mt-5">
      <div className="row scrollable-div mt-5">
        <div>
          <h3>You subscription has been expire Please renew.</h3>
        </div>
        <div className="mt-5" style={{ flexDirection: "column" }}>
          {/* <div className="mb-4"> */}
          <div className="mb-4">
            <CardElement
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    color: "#424770",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                    padding: "10px 12px",
                    lineHeight: "24px",
                    display: "block",
                    width: "100%",
                  },
                  invalid: {
                    color: "#9e2146",
                  },
                },
              }}
            />
          </div>
          <div>
            <button
              type="submit"
              className="btn btn-block btn_login w-100"
              disabled={!stripe}
              onClick={async () => {
                if (!stripe || !elements) {
                  return;
                }

                const cardElement = await elements.getElement(CardElement);

                const { error, paymentMethod } =
                  await stripe.createPaymentMethod({
                    type: "card",
                    card: cardElement,
                  });

                
                if (!error) {
                  await reSubscriptionApi({ paymentId: paymentMethod.id });
                }
              }}
            >
              {loading ? "Processing..." : "Pay"}
              {offer?.threeMonth?.stripePlanId === userData.planId
                ? `$ ${offer?.threeMonth?.price} every three months`
                : offer?.sixMonth?.stripePlanId === userData.planId
                ? `$ ${offer?.sixMonth?.price} every six months`
                : offer?.year?.stripePlanId === userData.planId
                ? `$ ${offer?.year?.price} every year`
                : "00"}
            </button>
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default PaymentPageResubscription;
