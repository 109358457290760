import React from "react";

const Term = () => {
  return (
    <div className="container mb-4 mb-xl-5 pt-5 pb-4 pb-xl-5">
      <div className="row py-4 py-xl-5 justify-content-center">
        <div className="col-xl-12 mt-5">
          <h1 className="home_title mb-3">Terms and Conditions of Use</h1>
          <p className="home_subtext">Last Updated: January 28, 2024</p>
          <p className="home_subtext">
            HANNA HEALTH, LLC (“Hanna Health”, “us”, “we” or “our”) are
            pleased to provide you with access to and use of our websites,
            content, products, services, and any other materials (collectively,
            “HANNA HEALTH Services”) that we may provide.
          </p>
          <p className="home_subtext">
            PLEASE READ CAREFULLY: THESE TERMS AND CONDITIONS CONTAIN A BINDING
            ARBITRATION PROVISION AND CLASS ACTION WAIVER (SECTION 9) WHICH
            AFFECT YOUR RIGHTS RELATED TO DISPUTES YOU MAY HAVE WITH HANNA HEALTH
            . EXCEPT WHERE PROHIBITED BY LAW AND AS SET FORTH IN SECTION
            9, BY PURCHASING, ENGAGING WITH OR OTHERWISE USING ANY HANNA HEALTH
            SERVICES, YOU EXPRESSLY AGREE THAT DISPUTES BETWEEN YOU AND HANNA   
            HEALTH WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION, AND
            YOU HEREBY WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT
            OR CLASS-⁠WIDE ARBITRATION. IF YOU DO NOT AGREE TO THESE TERMS AND
            CONDITIONS, PLEASE IMMEDIATELY CEASE USE OF ANY HANNA HEALTH
            SERVICES.
          </p>
          <p className="home_subtext">
            THE HANNA HEALTH SERVICES ARE NOT INTENDED FOR ANYONE UNDER THE AGE
            OF 13. IF YOU ARE 13 TO 17 YEARS OF AGE, YOU MAY ONLY ACCESS AND/OR
            USE ANY HANNA HEALTH SERVICES WITH THE INVOLVEMENT AND APPROVAL OF
            YOUR PARENT OR GUARDIAN.
          </p>
          <p className="home_subtext">
            <spam className=" fw-semibold">
              Contract Between You and HANNA HEALTH; Privacy Policy.
            </spam>
            <br />
            <br />
            These Terms and Conditions of Use (“Terms”) and our Privacy Policy
            (available here) form a legally binding agreement between you and
            HANNA HEALTH, and govern your access and use, and our provision of,
            the HANNA HEALTH Services and any other technology, content, items
            or other materials provided by or through HANNA HEALTH, or
            otherwise on which these Terms are provided (including via links).
            BY ACCESSING OR USING ANY HANNA HEALTH SERVICE, YOU AGREE TO BE
            BOUND AND ABIDE BY THESE TERMS (INCLUDING WITHOUT LIMITATION SECTION
            9 BELOW), OUR PRIVACY POLICY AND ANY AMENDMENTS THERETO, AND YOU
            AGREE YOU ARE AT LEAST 13 YEARS OF AGE.
          </p>
          <p className="home_subtext">
            <spam className=" fw-semibold">Changes to These Terms.</spam>
            <br />
            <br />
            We may in our sole and absolute discretion change these Terms or our
            Privacy Policy from time to time to comply with laws or to meet our
            changing business requirements. These revisions shall be effective
            for new users immediately upon being posted to the HANNA HEALTH
            Services; however, for existing customers, the applicable revisions
            shall be effective 30 days after posting unless otherwise stated. If
            you do not agree with any of the changes, you must discontinue using
            any and all HANNA HEALTH Services. By continuing to use any HANNA 
            HEALTH Services after the 30-day period referenced above in this
            Section, you expressly accept any applicable changes. <br /> <br />
            HANNA HEALTH’s Intellectual Property; Limited License to HANNA 
            HEALTH Services.
          </p>
          <p className="home_subtext">
            <spam className=" fw-semibold">Ownership.</spam>
            <br /> <br />
            You acknowledge and agree that the HANNA HEALTH Services, and any
            logos, names, designs, text, graphics, content, files, materials,
            and any other intellectual property rights contained therein,
            including without limitation any copyrights, patents, trademarks,
            proprietary or other rights contained therein, related thereto, or
            otherwise arising therefrom are owned by HANNA HEALTH, LLC or its
            affiliates, licensors, or suppliers. Furthermore, you acknowledge
            and agree that the source and object code of certain HANNA HEALTH
            Services and the format, directories, queries, algorithms, structure
            and organization of the same are the intellectual property,
            proprietary and confidential information of HANNA HEALTH and/or its
            affiliates, licensors and suppliers. You expressly agree that you
            will do nothing inconsistent with HANNA HEALTH’s ownership of the
            HANNA HEALTH Services, and that you gain no rights, title, or
            interest in or to any HANNA HEALTH Services or any goodwill
            associated therewith, except as stated in these Terms or any
            executed written agreement between you and HANNA HEALTH. In
            addition, except as expressly set forth in these Terms, you are not
            conveyed any right or license by implication, estoppel, or otherwise
            in or under any patent, trademark, copyright, or other proprietary
            right of HANNA HEALTH or any third party. Any and all goodwill
            arising from your use of any HANNA HEALTH Services shall inure
            solely to the benefit of HANNA HEALTH.
          </p>
          <p className="home_subtext">
            <spam className=" fw-semibold">Limited License.</spam>
            <br /> <br />
            For any HANNA HEALTH Services which enable you to use any videos,
            content, or other materials owned or licensed by us, we grant you a
            limited, revocable, non-exclusive, non-sublicensable,
            non-transferable license solely to access and use the specific HANNA 
            HEALTH Services, and any related content, or other materials FOR
            YOUR PERSONAL, NON-COMMERCIAL USE ONLY.
          </p>
          <p className="home_subtext">
            <spam className=" fw-semibold">Restrictions.</spam>
            <br /> <br />
            You are prohibited from, and expressly agree that you will not: (i)
            remove identification, warnings, disclaimers, disclosures, copyright
            or other proprietary notices in or on the HANNA HEALTH Services;
            (ii) access or use any HANNA HEALTH Services in an unlawful or
            unauthorized manner or in a manner that suggests an association with
            our content, products, services or brands, unless you have an
            executed agreement with us that allows for such activity; (iii) use,
            alter, copy, modify, store, sell, reproduce, distribute, republish,
            download, publicly perform, display, post, transmit, create
            derivative works of, or exploit any HANNA HEALTH Products or any
            part thereof; (iv) sell, resell, or make commercial use of the HANNA 
            HEALTH.
          </p>
          <p className="home_subtext">
            <spam className=" fw-semibold">Disclaimer of Warranties.</spam>
            <br /> <br />
            YOU ACKNOWLEDGE AND AGREE THE HANNA HEALTH SERVICES, INCLUDING
            WITHOUT LIMITATION, ANY PRODUCTS, SERVICES, WEBSITES, HEALTH,
            FITNESS, AND WELLNESS CONTENT AND ADVICE, OR ANY OTHER INFORMATION
            PROVIDED THEREIN ARE PROVIDED “AS IS” AND “AS AVAILABLE”, WITH ALL
            FAULTS, ERRORS, AND OMISSIONS, AND WITHOUT ANY PERFORMANCE
            ASSURANCES OR GUARANTEES OF ANY KIND. WE EXPRESSLY DISCLAIM ANY AND
            ALL WARRANTIES, CONDITIONS, AND REPRESENTATIONS, EXPRESS OR IMPLIED,
            REGARDING THE HANNA HEALTH SERVICES, INCLUDING WITHOUT LIMITATION
            ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, NON-INFRINGEMENT, SATISFACTORY QUALITY, ACCESSIBILITY, AND
            WARRANTIES ARISING FROM COURSE OF DEALING, USAGE OR TRADE PRACTICE.
            WE MAKE NO GUARANTEE OR WARRANTY THAT THE HANNA HEALTH SERVICES
            WILL MEET YOUR REQUIREMENTS OR THAT THEY WILL BE ERROR-FREE. WE
            FURTHER MAKE NO GUARANTEE OR WARRANTY AS TO THE PARTICULAR HEALTH
            AND WELLNESS GOALS, RESULTS, BENEFITS OR OUTCOMES THAT MAY BE
            ACHIEVED OR OBTAINED THROUGH USE OF ANY HANNA HEALTH SERVICES. YOU
            AGREE TO USE THE HANNA HEALTH SERVICES AT YOUR SOLE RISK.
            <br />
            YOU WILL NOT HOLD HANNA HEALTH OR ITS THIRD-PARTY SERVICE
            PROVIDERS, LICENSORS AND SUPPLIERS, AS APPLICABLE, RESPONSIBLE FOR
            ANY LOSS OR DAMAGE THAT RESULTS FROM YOUR ACCESS TO OR USE OF THE
           HANNA HEALTH SERVICES.{" "}
          </p>
          <p className="home_subtext">
            <spam className=" fw-semibold">Mobile Networks; Texting.</spam>
            <br /> <br />
            When you sign up to receive text messages from us, you acknowledge
            and agree you will receive such text messages using an autodialer to
            the number you provide, and that your consent for us to send you
            text messages is not a condition of purchasing any HANNA HEALTH
            Services. Not all carriers may be included within our text messaging
            programs. You are free to opt-out of receiving text messages from us
            at any time. Please see any additional instructions provided to you
            at the time you sign up.
          </p>
          <p className="home_subtext">
            <spam className=" fw-semibold">
              Your Content and Account.
              <br /> <br />
              User Generated Content.
            </spam>
            <br /> <br />
            The HANNA HEALTH Services may allow you and users of our websites
            to submit, upload or otherwise make available text, personally
            identifiable information (including health, wellness, and exercise
            data), feedback about our products and services or other content
            (“User Generated Content”). User Generated Content that you submit
            will be stored, maintained and used by HANNA HEALTH in accordance
            with our Privacy Policy.
            <br />
            <br />
            You may not submit or upload User Generated Content that is illegal,
            infringing, false, defamatory, harassing, threatening, bigoted,
            hateful, violent, vulgar, obscene, pornographic, negative or
            otherwise offensive or that harms or can reasonably be expected to
            harm any person or entity, whether or not such material is protected
            by law, as determined by HANNA HEALTH in its sole and absolute
            discretion. We have the right, but not the obligation, to monitor,
            screen, post, remove, modify, store and review User Generated
            Content or communications you submit, at any time and for any or no
            reason, including to ensure that the User Generated Content or
            communication conforms to these Terms, without prior notice to you.{" "}
          </p>
          <p className="home_subtext">
            <spam className=" fw-semibold">User Conduct.</spam>
            <br /> <br />
            You must only use the HANNA HEALTH Services for lawful purposes and
            in compliance with any applicable Codes of Conduct, and you must not
            use them in a way that is illegal or harmful, or infringes the
            rights of anyone else or that restricts or inhibits anyone else's
            enjoyment of any HANNA HEALTH Services. In using any HANNA HEALTH
            Services, and in particular, our websites, you expressly acknowledge
            you are prohibited from, and agree that you will not without our
            prior express written consent:
          </p>
          <ul className="tearm_point">
            <li>
              use the content on the HANNA HEALTH Services for any commercial
              exploitation whatsoever;
            </li>
            <li>
              disrupt or interfere with the security of, or otherwise abuse, the
              HANNA HEALTH Services, or any services, system resources,
              accounts, servers, or networks connected to or accessible through
              the HANNA HEALTH Services or affiliated or linked sites;
            </li>
            <li>
              access content, data or portions of the HANNA HEALTH websites
              which are not intended for you, or log onto a server or account
              that you are not authorized to access;
            </li>
            <li>
              attempt to probe, scan, or test the vulnerability of the HANNA 
              HEALTH Services, including websites, applications, or any
              associated system or network, or breach security or authentication
              measures without proper authorization;
            </li>
            <li>
              access any HANNA HEALTH Services or our websites through any
              automated means, such as “robots,” “spiders,” or “offline
              readers”;
            </li>
            <li>
              interfere or attempt to interfere with the use of the HANNA 
              HEALTH Services by any other user, host or network, including,
              without limitation by means of submitting a virus, overloading,
              "flooding," "spamming," "mail bombing," or "crashing";
            </li>
            <li>
              use any data mining, “scraping”, web crawling, robots, or similar
              data gathering and extraction methods on the HANNA HEALTH
              Services;
            </li>
            <li>
              upload, post, or otherwise transmit through or on the HANNA 
              HEALTH Services any viruses or other harmful, disruptive, or
              destructive files;
            </li>
            <li>
              use, frame, or utilize framing techniques to enclose any HANNA 
              HEALTH trademark, logo, or other proprietary information
              (including the images found at our websites, the content of any
              text, or the layout/design of any page or form contained on a
              page) without HANNA HEALTH's express written consent;
            </li>
            <li>
              use meta tags or any other "hidden text" utilizing a HANNA HEALTH
              name, trademark, or product name without HANNA HEALTH's express
              written consent;
            </li>
            <li>
              deeplink to the HANNA HEALTH Services, including our websites
              without HANNA HEALTH's express written consent;
            </li>
            <li>
              create or use a false identity on the HANNA HEALTH Services,
              share your account information, use another individual’s account
              information, or allow any person besides yourself to use your
              account to access the HANNA HEALTH Services;
            </li>
            <li>
              harvest or otherwise collect information about HANNA HEALTH
              users, including email addresses and phone numbers; and/or
            </li>
            <li>
              download, “rip,” or otherwise attempt to obtain unauthorized
              access to any HANNA HEALTH Services, content or other materials.{" "}
            </li>
          </ul>
          <p className="home_subtext">
            <spam className=" fw-semibold">Your Account; Passwords.</spam>
            <br /> <br />
            Certain HANNA HEALTH Services permit or require you to create an
            account to use the services and benefits that we provide. You agree
            to provide and maintain accurate, current and complete information
            for your accounts, including as applicable, your name, contact, and
            payment information. You agree not to impersonate or misrepresent
            your affiliation with any person or entity, including using another
            person’s username, password or other account information, or another
            person’s name, image, photo or likeness, or if applicable, provide
            false details for a parent or guardian. You agree that we may take
            steps to verify the accuracy of information you provide.
            <br />
            <br />
            You are responsible for maintaining the confidentiality of your
            username and password, and you are responsible for all activities
            under your account that you can reasonably control. You may not
            share your password or other login information with any person; any
            use of your account by any person other than yourself is grounds for
            suspension or termination of your account. You agree to promptly
            notify us of any unauthorized use of your username, password or
            other account information, or of any other breach of security that
            you become aware of involving your account or the HANNA HEALTH
            Services. You agree not to use the account, username or password of
            any other account holder at any time. HANNA HEALTH will not be
            liable for any loss that you incur as a result of someone else using
            your password, either with or without your knowledge.
            <br />
            <br />
            HANNA HEALTH’s Products/Services; Websites; Orders.
          </p>
          <p className="home_subtext">
            <spam className=" fw-semibold">Our Guarantee.</spam>
            <br /> <br />
            Most of our products and services carry a three (7) day money back
            guarantee, return or exchange policy starting from the date that the
            product or service is delivered or supplied to you. Please review
            the information provided with your product/service for specific
            details. <br />
            <br />
            To exercise your money back guarantee and/or return or exchange a
            product, log in to your account on https://hannahealthhub.com and locate your
            order. If your order is eligible for a return, you may initiate the
            return by following the instructions provided to return the product.
            Products that come with a money back guarantee will receive a refund
            of the purchase price, less applicable shipping and handling, unless
            stated otherwise. In some cases, you may also be responsible for
            paying to ship any products back to us. We will not process a refund
            or reship for products returned without our authorization, returned
            products sent to the wrong address, or any loss or damage to
            returned products that occur in transit. If returned products are
            lost or damaged in transit, we reserve the right to charge you for,
            or not refund any amounts attributable to, any such lost or damaged
            products. Should you experience any difficulty or delay in returning
            a product and securing the proper refund, please visit
            https://hannahealthhub.com.
            <br />
            <br />
            Product and Service Descriptions, Price, and Other Information.
            <br />
            <br />
            We have taken reasonable precautions to ensure that all product
            descriptions, prices and other information shown on our websites are
            correct and fairly described. However, when ordering products or
            services through our websites, please note that:
          </p>
          <ul className="tearm_point">
            <li>
              HANNA HEALTH reserves the right to not accept any orders if there
              is a material error in the description of the product or service,
              or if the price advertised is incorrect;
            </li>
            <li>
              HANNA HEALTH reserves the right to refuse or discontinue the
              supply of any product or service to any customer, or change,
              suspend or discontinue any aspect of our websites at any time in
              our sole and absolute discretion;
            </li>
            <li>
              all prices are displayed in United States Dollars unless expressly
              indicated otherwise;
            </li>
            <li>
              packaging and contents may vary from that shown on our websites;
            </li>
            <li>
              any weights, dimensions, and capacities shown on our websites are
              approximate only;
            </li>
            <li>
              when you place an order, we estimate the tax applicable to your
              order and include that estimate in the total for your convenience
              (the final tax amount will be based on the then-current rate as
              established by the applicable taxing authority, charged to your
              payment method, and reflected in the order confirmation and
              package invoice we provide to you); and
            </li>
            <li>
              all items are subject to availability and we will inform you as
              soon as reasonably possible if any product or service you ordered
              is not available and whether we may offer you an alternative of
              equal or higher quality and value.
            </li>
          </ul>
          <p className="home_subtext">
            Please also note that we may be required to change the terms of any
            products, goods, or services that we offer and/or you purchase. This
            includes without limitation changes to prices, taxes, shipping and
            handling amounts, specifications, delivery times, and/or package
            contents. We will provide you with prior notice of any changes, by
            posting a notice on our websites, sending an e-mail to you at the
            address you provided at the time of purchase, or by including a
            notice in any products you receive on an automatic recurring or
            subscription basis. HANNA HEALTH will not incur any obligation as a
            result of such change. As a valued customer, you are always free to
            cancel unshipped orders at any time by calling us at 615 212-5360.
            By accepting our products, goods and/or services after we have
            notified you of a change to any terms, you will be deemed to have
            accepted the change.
          </p>
          <p className="home_subtext">
            <spam className=" fw-semibold">Order Processing.</spam>
            <br /> <br />
            We reserve the right to refuse or cancel any order prior to
            delivery. Some situations that may result in your order being
            cancelled include system or typographical errors, inaccuracies in
            product or pricing information or product availability, fairness
            among customers where supplies are limited, or problems identified
            by our credit or fraud departments. We also may require additional
            verification or information before accepting an order. We will
            contact you if any portion of your order is cancelled or if
            additional information is required to accept your order. If your
            order is cancelled after we have processed your payment but prior to
            delivery, we will of course refund your payment.
            <br />
            <br />
            All purchases are subject to applicable shipping and handling, and
            sales and/or other taxes, which will be applied to your order total.
            For non-shippable goods, sales and/or other taxes will be calculated
            based on the billing address provided. For shippable goods, sales
            and/or other taxes will be calculated based on the shipping address
            provided. For orders that consist of both shippable and
            non-shippable goods, sales and/or other taxes will be calculated
            based on the shipping address provided.
          </p>
          <p className="home_subtext">
            <spam className=" fw-semibold">Taxes.</spam>
            <br /> <br />
            For purchases where sales or transaction tax is applicable you will
            see the tax calculated on the checkout page before you are asked to
            confirm the purchase. Tax shown at checkout is an estimate and the
            actual tax collected may differ once we process the order. We use
            commercially reasonable efforts to calculate and remit the correct
            amount of tax required on each taxable purchase, but we do not
            guarantee the accuracy of the amount of the tax represented as owed.
            Minor errors may occur owing to the inability to accurately track
            multiple taxing districts, state and local “tax holidays,” and
            timing of rate changes or the application of certain taxes to
            categories of items we sell. As a result of any error, we may over
            collect or under collect your tax. In consideration of our allowing
            you access to the use of the HANNA HEALTH Services, and in
            collecting and remitting taxes required on your purchases, you
            hereby waive your right to claim that the tax collected on any
            purchase is incorrect in any respect and you agree to hold us, our
            officers, directors, employees, agents and representatives, harmless
            from and against any claim, action, demand, loss, suit, or damages
            (including attorneys' fees) made or incurred as a result of our
            error in calculating the taxes you owe for your purchases.
          </p>
          <p className="home_subtext">
            <spam className=" fw-semibold">
              Your Personal and Payment Information.
            </spam>
            <br /> <br />
            When you provide any information to us for any reason, such as to
            sign up for an offer, use our mobile applications, or purchase our
            HANNA HEALTH Services, you agree to only provide true, accurate,
            current, and complete information. By providing any credit card or
            other payment method information to us, you represent that such
            payment information is correct, and belongs to you or you have the
            authority to use such payment method. In the case of e-mail, you
            must provide an accurate e-mail address that is registered to you.
            You are responsible for promptly updating your information with any
            changes, especially to keep your billing information current. You
            must promptly notify us if your payment method is cancelled (for
            example, for loss or theft). Changes to such information can be made
            by contacting us at  admin@hannahealth.com. To help keep your
            account current and prevent service interruption, you acknowledge
            HANNA HEALTH may update your payment method on file when it is set
            to expire or based on updates it receives from the bank that issues
            your payment method. You agree that your placement of an electronic
            order on our websites is sufficient to satisfy any applicable
            Statute of Frauds, and no further writing is required. If you are
            accessing, using and/or purchasing any HANNA HEALTH Services on
            behalf of a company, you represent you have sufficient authority to
            bind that company to these Terms.
          </p>
          <p className="home_subtext">
            <spam className=" fw-semibold">Shipping.</spam>
            <br /> <br />
            We will take commercially reasonable efforts to ship your products
            within the timeframe we provided to you at the time of purchase.
            Please see the tracking information provided with your order
            confirmation for approximate delivery timeframes.
            <br />
            <br />
            Please note that any timeframes we provide are good faith estimates
            and may be subject to change. We will take commercially reasonable
            steps to inform you in writing of any shipping delays that extend
            beyond the timeframe we provided at the time of purchase. Product
            availability may be limited in some cases and not available for
            immediate shipment. In that case, products may be delivered in
            separate shipments depending on availability. For example, if your
            purchase included two or more items, we may ship those products
            which are in stock, and later ship the other products to you when
            they become available. You are always welcome to contact us if you
            wish to cancel or modify your order due to a delay in shipping. We
            will not be liable beyond refunding your purchase price if there is
            a delay in delivering your product(s). We are not responsible for
            shipments which are returned to sender or refused at delivery, or
            shipping delays due to factors outside of our reasonable control,
            such as bad weather, natural disasters, strikes, protests,
            government disruptions, etc.
            <br />
            <br />
            Since we are not authorized to sell our products in all
            jurisdictions, we may reject certain purchases based on the shipping
            address provided. Please note we reserve the right to set
            appropriate shipping terms for shipments to certain foreign
            jurisdictions that are fulfilled from U.S. warehouse locations.
          </p>
          <p className="home_subtext">
            <spam className=" fw-semibold">International Orders.</spam>
            <br /> <br />
            We do not directly sell certain products in any jurisdiction other
            than the United States as these products may not be approved for
            sale in other jurisdictions. While HANNA HEALTH may choose to
            accept orders for the purchase of its products from non-residents of
            the United States, the acceptance of such orders and the sale of
            such products will only be based on the following conditions
            precedent:
          </p>
          <ul className="tearm_point">
            <li>
              you agree that the purchase of any HANNA HEALTH products by you,
              as a non-resident of the United States, shall be (a) ex works HANNA
              HEALTH's facilities (which may exist anywhere in the world) per
              Incoterms 2010, with all title risk and loss in the products
              passing to you from such facility and (b) for your own personal
              use only and not for further resale or distribution in any manner;
            </li>
            <li>
              you agree not to order more than a ninety (90) day supply of any
              consumable products within any ninety (90) day period;
            </li>
            <li>
              you hereby expressly authorize and direct HANNA HEALTH to load
              and ship the purchased products to you to your designated ship to
              destination, and to contract on your behalf with a common carrier
              or courier company for that purpose;
            </li>
            <li>
              you are the principal importer of record, responsible for ensuring
              any products can be lawfully imported into your country, and will
              undertake responsibility for any and all applicable taxes,
              shipping, customs clearance, duties and import requirements from
              HANNA HEALTH's facilities to your foreign ship to destination;
              and
            </li>
            <li>
              the United Nations Convention on the International Sale of Goods
              shall not apply to any purchase or sale, and HANNA HEALTH
              expressly opts out of such application.
            </li>
          </ul>
          <p className="home_subtext">
            For products shipped outside the United States, please note that
            some HANNA HEALTH Services, including without limitation, exercise
            programs, content, manuals, instructions and safety warnings may not
            be localized in destination country languages; and the products,
            goods, services and accompanying materials may not be designed in
            accordance with destination country standards, specifications, and
            labeling requirements. In those cases, you expressly accept those
            HANNA HEALTH Services as they are sold.
            <br />
            <br />
            The HANNA HEALTH Services Do Not Constitute Medical Advice or Other
            Professional Healthcare Advice. Although HANNA HEALTH provides its
            products and services with your health and safety in mind, each
            individual has their own limitations and it is therefore critical
            that you consult your physician or other licensed healthcare
            provider for necessary medical or appropriate care, follow all
            safety and other instructions provided by HANNA HEALTH, and obtain
            appropriate authorization before accessing or using any HANNA
            HEALTH Services, especially if you are prone to injuries, are
            pregnant or nursing, are under 18 years of age, or have any other
            unique or special medical conditions. ALL HANNA HEALTH SERVICES ARE
            PROVIDED FOR ENTERTAINMENT AND INFORMATIONAL PURPOSES ONLY, AND ARE
            NOT INTENDED TO DIAGNOSE ANY MEDICAL OR HEALTHCARE CONDITION,
            REPLACE THE ADVICE OF A LICENSED HEALTHCARE PROFESSIONAL, OR PROVIDE
            ANY MEDICAL ADVICE, OPINION, DIAGNOSIS, TREATMENT OR GUARANTEE. HANNA
            HEALTH DOES NOT PROVIDE CLINICAL SERVICES. WE ARE SOLELY PROVIDING
            INFORMATIONAL AND EDUCATIONAL SELF-HELP RESOURCES. THUS, IT IS
            EXTREMELY IMPORTANT THAT YOU CONSULT YOUR PHYSICIAN OR LICENSED
            HEALTHCARE PROVIDER – AND NOT HANNA HEALTH, IN CONNECTION WITH YOUR
            MEDICAL OR HEALTH CONDITION. YOUR USE OF ANY HANNA HEALTH SERVICES
            DOES NOT CREATE A DOCTOR-PATIENT, PROFESSIONAL-CLIENT, OR SIMILAR
            RELATIONSHIP BETWEEN YOU AND HANNA HEALTH.{" "}
          </p>
          <p className="home_subtext">
            <spam className=" fw-semibold">
              Any individual results using HANNA HEALTH Services may vary.{" "}
            </spam>
            <br /> <br />
            Safety Warnings. HANNA HEALTH provides safety warnings, along with
            care and use instructions for certain exercises and services. You
            must always carefully read and follow all such warnings and
            instructions prior to beginning any exercise, or service. <br />
            <br />
            Age Restrictions. You must always adhere to any minimum age
            restrictions and limitations related to any HANNA HEALTH Services.
            <br />
            <br />
            Assumption of Risk. You expressly acknowledge and agree that your
            access, use and/or involvement with any HANNA HEALTH Products and
            Services (including without limitation, exercise, and fitness
            products), may involve potentially dangerous and physical activities
            that may lead to personal and/or bodily injury, death, temporary or
            permanent disability, loss of services, loss of consortium, or
            damage to or loss of property or privacy. You hereby acknowledge and
            willingly accept these risks and agree to unconditionally release
            and hold HANNA HEALTH harmless from and against all claims, suits,
            damages, losses, causes of action, costs, expenses or liability
            arising out of or related to your access, use and/or involvement
            with any HANNA HEALTH Services.
          </p>
          <p className="home_subtext">
            <spam className=" fw-semibold">
              Copyright Policy and Copyright Agent.
            </spam>
            <br /> <br />
            It is HANNA HEALTH's policy to respect the copyright and other
            intellectual property rights of others. HANNA HEALTH may remove
            content from its websites or other properties that appears to
            infringe the copyright or other intellectual property rights,
            including moral rights, of others. In addition, HANNA HEALTH may
            terminate access by users who appear to infringe the copyright or
            other intellectual property rights of others.{" "}
          </p>
          <p className="home_subtext">
            <spam className=" fw-semibold">
              DISPUTES, BINDING INDIVIDUAL ARBITRATION, AND WAIVER OF CLASS
              ACTIONS AND CLASS ARBITRATIONS.
            </spam>
            <br /> <br />
            Disputes. The terms of this Section shall apply to all Disputes
            between you and HANNA HEALTH. For the purposes of this Section,
            “Dispute” shall mean any dispute, claim, or action between you and
            HANNA HEALTH arising under or relating to any HANNA HEALTH
            Services, HANNA HEALTH’s websites, these Terms, or any other
            transaction involving you and HANNA HEALTH, whether in contract,
            warranty, misrepresentation, fraud, tort, intentional tort, statute,
            regulation, ordinance, or any other legal or equitable basis, and
            shall be interpreted to be given the broadest meaning allowable
            under law. YOU AND HANNA HEALTH AGREE THAT “DISPUTE” AS DEFINED IN
            THESE TERMS SHALL NOT INCLUDE ANY CLAIM OR CAUSE OF ACTION BY YOU OR
            HANNA HEALTH FOR (i) TRADE SECRET MISAPPROPRIATION, (ii) PATENT
            INFRINGEMENT, (iii) COPYRIGHT INFRINGEMENT OR MISUSE, AND (iv)
            TRADEMARK INFRINGEMENT OR DILUTION. Moreover, notwithstanding
            anything else in these Terms, you agree that a court, not the
            arbitrator, shall decide if a claim falls within one of these four
            exceptions.
            <br />
            <br />
            Binding Arbitration. You and HANNA HEALTH further agree: (i) to
            arbitrate all Disputes between the parties pursuant to the
            provisions in these Terms; (ii) these Terms memorialize a
            transaction in interstate commerce; (iii) the Federal Arbitration
            Act (9 U.S.C. §1, et seq.) in the U.S. governs the interpretation
            and enforcement of this Section; and (iv) this Section shall survive
            termination of these Terms. ARBITRATION MEANS THAT YOU WAIVE YOUR
            RIGHT TO A JUDGE OR JURY IN A COURT PROCEEDING AND YOUR GROUNDS FOR
            APPEAL ARE LIMITED. The arbitrator may award you the same damages
            and relief as a court sitting in proper jurisdiction could, and may
            award declaratory or injunctive relief. In addition, in some
            instances, the costs of arbitration could exceed the costs of
            litigation and the right to discovery may be more limited in
            arbitration than in court. The decision of the arbitrator shall be
            final and enforceable by any court with jurisdiction over the
            parties.
            <br />
            <br />
            Dispute Notice. In the event of a Dispute, you or HANNA HEALTH must
            first send to the other party a notice of the Dispute that shall
            include a written statement that sets forth the name, address and
            contact information of the party giving it, the facts giving rise to
            the Dispute, and the relief requested (the “Dispute Notice”). The
            Dispute Notice to HANNA HEALTH must be addressed to: HANNA HEALTH,
            LLC, Attn.: CEO (the “HANNA HEALTH Notice Address”). The Dispute
            Notice to you will be sent by certified mail to the most recent
            address we have on file or otherwise in our records for you. If HANNA
           HEALTH and you do not reach an agreement to resolve the Dispute
            within sixty (60) days after the Dispute Notice is received, you or
            HANNA HEALTH may commence an arbitration proceeding pursuant to
            this Section. Following submission and receipt of the Dispute
            Notice, each of us agrees to act in good faith to seek to resolve
            the Dispute before commencing arbitration.
            <br />
            <br />
            WAIVER OF CLASS ACTIONS AND CLASS ARBITRATIONS. YOU AND HANNA
            HEALTH AGREE THAT EACH PARTY MAY BRING DISPUTES AGAINST THE OTHER
            PARTY ONLY IN AN INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR
            CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING,
            INCLUDING WITHOUT LIMITATION FEDERAL, STATE OR PROVINCIAL CLASS
            ACTIONS, OR CLASS ARBITRATIONS. ACCORDINGLY, UNDER THE ARBITRATION
            PROCEDURES OUTLINED IN THIS SECTION, AN ARBITRATOR SHALL NOT COMBINE
            OR CONSOLIDATE MORE THAN ONE PARTY’S CLAIMS WITHOUT THE WRITTEN
            CONSENT OF ALL AFFECTED PARTIES TO AN ARBITRATION PROCEEDING.
            WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, YOU AND HANNA
            HEALTH AGREE THAT NO DISPUTE SHALL PROCEED BY WAY OF CLASS
            ARBITRATION WITHOUT THE WRITTEN CONSENT OF ALL AFFECTED PARTIES.
            <br />
            <br />
            Arbitration Procedure. If a party elects to commence arbitration,
            the arbitration shall be governed by: in the U.S., the expedited
            Commercial Rules of the American Arbitration Association (“AAA”)
            that are in effect at the time the arbitration is initiated (the
            “AAA Rules”), available at https://www.adr.org/or by, and under the
            rules set forth in these Terms; except that AAA may not administer
            any multiple claimant or class arbitration, as the parties agree
            that the arbitration shall be limited to the resolution only of
            individual claims. If there is a conflict between the AAA Rules or
            ADR Chambers Rules, as applicable, and the rules set forth in these
            Terms, the rules set forth in these Terms shall govern. You may, in
            arbitration, seek any and all remedies otherwise available to you
            pursuant to federal, state, provincial, territorial, or local laws.
            All Disputes shall be resolved by a single neutral arbitrator, and
            both parties shall have a reasonable opportunity to participate in
            the selection of the arbitrator. The arbitrator is bound by the
            terms of these Terms. The arbitrator, and not any federal, state,
            provincial, territorial, or local court or agency, shall have
            exclusive authority to resolve all disputes arising out of or
            relating to the interpretation, applicability, enforceability, or
            formation of these Terms, including, but not limited to, any claim
            that all or any part of these Terms is void or voidable.
            Notwithstanding this broad delegation of authority to the
            arbitrator, a court shall determine the limited question of whether
            a claim or cause of action is for (i) trade secret misappropriation,
            (ii) patent infringement, (iii) copyright infringement or misuse, or
            (iv) trademark infringement or dilution, which are excluded from the
            definition of “Disputes” as stated above. The arbitrator shall be
            empowered to grant whatever relief would be available in a court
            under law or in equity. The arbitrator’s award shall be binding on
            the parties and may be entered as a judgment in any court of
            competent jurisdiction. You may choose to engage in arbitration
            hearings by telephone. Arbitration hearings not conducted by
            telephone shall take place in Davidson County, Tennessee.
            <br />
            <br />
            Initiation of Arbitration Proceeding. If either you or HANNA HEALTH
            decide to arbitrate a Dispute, we agree to the following procedure:
            <br />
            <br />
            Write a Demand or Notice:
            <br />
            <br />
            If in the U.S., write a Demand for Arbitration. The demand must
            include a description of the Dispute and the amount of damages
            sought to be recovered (“Demand for Arbitration”).
            <br />
            <br />
            File the Demand for Arbitration or Notice to Arbitrate, plus the
            appropriate filing fee, to:
          </p>
          <p className="home_subtext">
            <spam className=" fw-semibold">AAA</spam>
            <br /> <br />
            <a href="https://adr.org/index.php/Support">
              https://adr.org/index.php/Support
            </a>
            <br />
            <br />
            Send one copy of the Demand for Arbitration or Notice to Arbitrate
            to the other party at the same address as the Dispute Notice, or as
            otherwise agreed to by the parties.
            <br />
            <br />
            Hearing Format. In all hearing formats, the arbitrator shall issue a
            written decision that explains the essential findings and
            conclusions on which an award, if any, is based. During the
            arbitration, the amount of any settlement offer made by HANNA
            HEALTH or you shall not be disclosed to the arbitrator until after
            the arbitrator determines the amount, if any, to which you or HANNA
            HEALTH is entitled. The discovery or exchange of non-privileged
            information relevant to the Dispute may be allowed during the
            arbitration.
            <br />
            <br />
            Arbitration Fees. HANNA HEALTH shall pay, or (if applicable)
            reimburse you for, all JAMS or ADR Chambers (as applicable) filing,
            administration, and arbitrator fees for any arbitration commenced
            (by you or HANNA HEALTH) pursuant to provisions of these Terms. You
            are responsible for all additional costs that you incur in the
            arbitration, including without limitation, fees for attorneys or
            expert witnesses.
            <br />
            <br />
            Amendments to this Section. Notwithstanding any provision in these
            Terms to the contrary, you and HANNA HEALTH agree that if HANNA
            HEALTH makes any future amendments to the dispute resolution
            procedure and class action waiver provisions (other than a change to
            HANNA HEALTH’s address) in these Terms, HANNA HEALTH will notify
            you and you will have thirty (30) days from the date of notice to
            affirmatively opt-out of any such amendments by sending a written
            letter to the HANNA HEALTH Notice Address within thirty (30) days
            of HANNA HEALTH’s notification that specifies: (i) your name; (ii)
            your mailing address; and (iii) your request to opt-out of such
            amendments. If you affirmatively opt-out of any future amendments,
            you are agreeing that you will arbitrate any Dispute between us in
            accordance with the language of this Section as stated in these
            current Terms, without any of the proposed amendments governing. If
            you do not affirmatively opt-out of any future amendments, you will
            be deemed to have consented to any such future amendments.
            <br />
            <br />
            Severability. If any provision in this Section is found to be
            unenforceable, that provision shall be severed with the remainder of
            these Terms remaining in full force and effect. The foregoing shall
            not apply to the prohibition against class or representative
            actions; if the prohibition against class or representative actions
            is found to be unenforceable, this entire Section shall be null and
            void. The terms of this Section shall otherwise survive any
            termination of these Terms.
            <br />
            <br />
            Exclusive Venue for Other Controversies. HANNA HEALTH and you agree
            that any controversy excluded from the dispute resolution procedure
            and class action waiver provisions in this Section (other than an
            individual action filed in small claims court) shall be filed only
            in the State Courts serving Davidson County, Tennessee, or the
            United States District Court serving Davidson County, Tennessee, and
            each party hereby irrevocably and unconditionally consents and
            submits to the exclusive jurisdiction of such courts for any such
            controversy.
          </p>
          <p className="home_subtext">
            <spam className=" fw-semibold">
              Indemnification; Limitation of Liability.
            </spam>
            <br /> <br />
            Indemnification. You agree to indemnify and hold HANNA HEALTH, its
            parents, subsidiaries, affiliates, shareholders, officers,
            directors, employees, agents, and suppliers harmless from and
            against any claim, action, demand, loss, suit, or damages (including
            attorneys' fees) made or incurred by any third party arising out of
            or relating to your improper use of any HANNA HEALTH Services, your
            violation of these Terms, or your violation of any rights of a third
            party.
            <br />
            <br />
            Limitation of Liability. TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN
            NO EVENT SHALL HANNA HEALTH, LLC, OR ANY OF ITS SUBSIDIARIES,
            AFFILIATES OR PARENT COMPANIES, OR ANY OF THEIR SERVICE PROVIDERS,
            LICENSORS OR SUPPLIERS BE LIABLE FOR SPECIAL, COMPENSATORY,
            INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, LOST
            PROFITS, LOST DATA OR CONFIDENTIAL OR OTHER INFORMATION, LOSS OF
            PRIVACY, COSTS OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES,
            FAILURE TO MEET ANY DUTY INCLUDING WITHOUT LIMITATION OF GOOD FAITH
            OR OF REASONABLE CARE, NEGLIGENCE, OR OTHERWISE, REGARDLESS OF THE
            FORESEEABILITY AND/OR IF WE WERE ADVISED OF SUCH DAMAGES OR OF ANY
            ADVICE OR NOTICE GIVEN TO HANNA HEALTH OR ITS SERVICE PROVIDERS,
            LICENSORS AND SUPPLIERS ARISING OUT OF OR IN CONNECTION WITH YOUR
            USE OF ANY HANNA HEALTH SERVICES. THIS LIMITATION SHALL APPLY
            REGARDLESS OF WHETHER THE DAMAGES ARISE OUT OF BREACH OF CONTRACT,
            NEGLIGENCE, TORT, OR ANY OTHER LEGAL THEORY OR FORM OF ACTION.
            ADDITIONALLY, THE MAXIMUM LIABILITY OF HANNA HEALTH AND ITS SERVICE
            PROVIDERS, LICENSORS AND SUPPLIERS TO YOU UNDER ALL CIRCUMSTANCES
            WILL BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU IN THE LAST
            NINETY (90) DAYS TO HANNA HEALTH FOR ANY HANNA HEALTH SERVICES.
            YOU AGREE THAT THIS LIMITATION OF LIABILITY REPRESENTS A REASONABLE
            ALLOCATION OF RISK AND IS A FUNDAMENTAL ELEMENT OF THE BASIS OF THE
            BARGAIN BETWEEN HANNA HEALTH AND YOU. THE HANNA HEALTH SERVICES
            WOULD NOT BE PROVIDED WITHOUT SUCH LIMITATIONS. HANNA HEALTH WILL
            NOT BE LIABLE FOR FAILURE TO PERFORM ANY OBLIGATION UNDER THESE
            TERMS IF SUCH FAILURE IS CAUSED BY THE OCCURRENCE OF ANY UNFORESEEN
            CIRCUMSTANCE BEYOND ITS REASONABLE CONTROL, INCLUDING, WITHOUT
            LIMITATION, INTERNET OUTAGES, COMMUNICATIONS OUTAGES, FIRE, FLOOD,
            NATURAL DISASTER, CIVIL UNREST OR WAR.
          </p>
          <p className="home_subtext">
            <span className="fw-semibold">Miscellaneous.</span>
            <br />
            <br />
            Governing Law. You agree that the laws of the State of Tennessee,
            without regard to principles of conflict of laws, will exclusively
            govern these Terms and Conditions and any Dispute between you and
            HANNA HEALTH. As the HANNA HEALTH Services are controlled by HANNA
            HEALTH from Tennessee, Tennessee law will apply regardless of your
            residence or the location where you use HANNA HEALTH Services.{" "}
            <br />
            International Users. Our websites are controlled, operated, and
            administered by HANNA HEALTH from its offices within the United
            States of America. HANNA HEALTH makes no representation or warranty
            that the programs, products, and services are appropriate or
            available for use at locations outside of the United States, You may
            not use the HANNA HEALTH Services or export the HANNA HEALTH
            Services in violation of U.S. export laws and regulations. If you
            access the HANNA HEALTH Services from a location outside of the
            United States, you are responsible for compliance with any and all
            local laws, rules, regulations, and ordinances.
            <br />
            <br />
            Notices. All notices required or permitted to be given under these
            Terms must be in writing. HANNA HEALTH may provide you notice by
            sending you an e-mail to the address on file with us, which you
            agree electronically satisfies any legal requirement that such
            notice be in writing. YOU BEAR THE SOLE RESPONSIBILITY OF ENSURING
            THAT YOUR E-MAIL ADDRESS ON FILE WITH HANNA HEALTH IS ACCURATE AND
            CURRENT, AND NOTICE TO YOU SHALL BE DEEMED EFFECTIVE UPON THE
            SENDING BY HANNA HEALTH OF AN EMAIL TO THAT ADDRESS. You shall give
            any notice to HANNA HEALTH by means of U.S. mail, postage prepaid,
            to HANNA HEALTH, LLC, , Attn: CEO. Such notice to HANNA HEALTH
            shall be effective upon receipt of notice by HANNA HEALTH.
            <br />
            <br />
            Severability. If any provision of these Terms (except for Section
            9), or a part thereof, shall be unlawful, void or for any reason
            unenforceable, then that provision or part thereof shall be deemed
            severable from these Terms and shall not affect the validity and
            enforceability of any remaining provisions or parts thereof.
            <br />
            <br />
            Suspension; Termination. Notwithstanding anything to the contrary in
            these Terms, HANNA HEALTH may, in its sole and absolute discretion,
            suspend, disable, block, or terminate your access and use of any
            HANNA HEALTH Services (including without limitation, deleting your
            account(s) or any part thereof), or block, remove or delete any User
            Generated Content that you submitted, for any lawful reason,
            including if HANNA HEALTH determines in its discretion that you
            violated these Terms (such as and including by way of example, to
            the extent your conduct or User Generated Content violates these
            Terms or could damage HANNA HEALTH’s reputation or goodwill). If
            HANNA HEALTH suspends, disables, blocks, terminates, or deletes
            your account, you may not re-register for or use the HANNA HEALTH
            Services under any other login or profile. HANNA HEALTH may block
            your access to the HANNA HEALTH Services to prevent
            re-⁠registration. You agree that HANNA HEALTH will not be liable
            for any interruption or termination of your access and/or use of the
            HANNA HEALTH Services.
            <br />
            <br />
            No Third Party Beneficiaries. Except as set forth in these Terms,
            only you and HANNA HEALTH may enforce these Terms; no third party
            shall be entitled to enforce these Terms.
            <br />
            <br />
            Survival. The provisions of these Terms which by their nature should
            survive the termination of these Terms shall survive such
            termination.
            <br />
            <br />
            Waiver. No waiver of any provision of these Terms by us shall be
            deemed a further or continuing waiver of such provision or any other
            provision, and our failure to assert any right or provision under
            these Terms shall not constitute a waiver of such right or
            provision. Any waiver must be in writing signed by the Chief Legal
            Officer of HANNA HEALTH in order to be effective.
            <br />
            <br />
            Assignment. HANNA HEALTH may assign these Terms to any person or
            entity at any time, for any reason, with or without notice to you.
            <br />
            <br />
            Amendments; Entire Agreement. These Terms may not be amended unless
            in a signed writing by the Chief Executive Officer of HANNA HEALTH.
            These Terms constitute the final, exclusive and complete agreement
            between you and HANNA HEALTH regarding the subject matter hereof
            and supersede all agreements, communications, and course of dealings
            between you- and HANNA HEALTH.
            <br />
            <br />
            Language. It is the express intent of the parties that these Terms
            and all related documents have been drafted in English (in the case
            of English versions) and French (in the case of French versions).
          </p>
          <p className="home_subtext">
            <span className="fw-semibold">BUYER’S RIGHT TO CANCEL</span>
            <br />
            <br />
            You may cancel this contract from the day you enter into the
            contract until three (7) days after you enter into this contract.
            You do not need a reason to cancel.
            <br />
            <br />
            If you cancel this contract, the seller has 15 days to refund your
            money, after you return the goods.
            <br />
            <br />
            To cancel, you must give notice of cancellation at the address in
            this contract. You must give notice of cancellation by a method that
            will allow you to prove that you gave notice, including registered
            mail, fax or by personal delivery.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Term;
