import React, { useState, useEffect, useContext } from "react";
import useApiAxios from "./../../api/useApiAxios";
import { Context } from "../../context/Context";

const ImageComponent = ({ src, className, onDoubleClick }) => {
  const [imageUrl, setImageUrl] = useState("/imgLoader.gif");

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getImageApi } = useApiAxios();
  const { contentUpdateCount } = useContext(Context);

  useEffect(() => {
    const fetchImageUrl = async () => {
      if (src.startsWith("data:im") || src.startsWith("/static")) {
        setImageUrl(src);
        setLoading(false);
      } else {
        const url = await getImageApi(src);
        if (url.error) {
          setError(true);

          setLoading(false);
        } else {
          setError(false);
          setImageUrl(url?.data);
          setLoading(false);
        }
      }
    };
    if (src && contentUpdateCount === 1) {
      fetchImageUrl();
    }
  }, [src]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading image: </div>;

  return (
    <img
      src={imageUrl}
      className={className}
      alt=""
      onDoubleClick={onDoubleClick}
    />
  );
};

export default ImageComponent;
