import React, { useContext} from "react";
import { Link } from "react-router-dom";
import VideoComponent from "../media/VideoComponent";
import { Context } from "../../context/Context";

const Dashboard = () => {
  const { userData } = useContext(Context);

  return (
    <>
      <div className="container banner_area px-3 px-md-0">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-lg-11 col-xl-9 z-10 pt-4">
            <h1 className="step_title text-center mb-3">
              Welcome To Hanna Health
            </h1>
            
            <p className="step_boldsubtext text-center">
              Exercises and resources to help you feel better, reduce pain and
              prevent injuries.
            </p>
          </div>
        </div>
      </div>
      {/* Banner end */}
      {/* VIRT Wellness Video Library */}
      <div className="">
        <div className="container mb-4 mb-xl-5 pt-3 z-10">
          <div className="row d-flex align-items-stretch justify-content-center">
            <div className="col-lg-11 col-xl-9 mb_24">
              <div className="video_play_area ">
                <a
                  href="#!"
                  data-bs-toggle="modal"
                  data-bs-target="#addcompany"
                >
                  <VideoComponent
                    className="img-fluid mx-auto mb-4"
                    src={`171817675446457415988971.mp4`}
                  />
                </a>
               
                <p className="video-text">Here you will find:</p>
                <h4><u>"Reduce Tightness and Move Better’’:</u></h4>
                <p className="video-text">
                  5-minute follow-along stretch exercises and quick mobility
                  exercises.
                </p>
                <h4><u>"Get Stronger":</u></h4>
                <p className="video-text">
                  quick follow-along full-body functional training classes
                </p>
                <p className="video-text">
                  pilates classes to improve your cardiovascular health and
                  strength.
                </p>
                <h4><u>"Educational":</u></h4>
                <p className="video-text">ergonomics and body mechanics</p>
                <p className="video-text">
                  videos explaining why you hurt, why pain sometimes persists,
                  and what you can do about it
                </p>
                <h4><u>"Decrease Pain":</u></h4>
                <p className="video-text">
                  exercises specific to your pain, helping you self-manage.
                </p>
              </div>
            </div>
            <div className="text-center mb-3">
              <Link
                to={
                  userData.activities.length === 0
                    ? "/question"
                    : "/question/painQuestion"
                }
                className="book1-btn  mb-5"
              >
                START MY JOURNEY
              </Link>
            </div>
          </div>
        </div>
      </div>

     
    </>
 
  );
};

export default Dashboard;
