import { createContext, useState } from "react";
import { toast } from "react-toastify";

export const Context = createContext();
export const AuthContextProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [sideBarEnable, setSideBarEnable] = useState(true);
  const [showPainModal, setShowPainModal] = useState(false);
  const [showSatisfactionModal, setShowSatisfactionModal] = useState(false);
  const [showAvgActivityModal, setShowAvgActivityModal] = useState(false);
  const [updateCount, setUpdateCount] = useState(0);
  const [contentUpdateCount, setContentUpdateCount] = useState(0);
  const [content, setContent] = useState({});
  const [openCalendlyModel, setOpenCalendlyModel] = useState(false);

  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const openErrorSnackbar = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const openInfoSnackbar = (message) => {
    toast.info(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  return (
    <Context.Provider
      value={{
        userData,
        setUserData,
        isAuthorized,
        setIsAuthorized,
        isLoading,
        setIsLoading,
        openInfoSnackbar,
        openErrorSnackbar,
        sideBarEnable,
        setSideBarEnable,
        updateCount,
        setUpdateCount,
        setContent,
        content,
        showPainModal,
        setShowPainModal,
        showSatisfactionModal,
        setShowSatisfactionModal,
        openCalendlyModel,
        setOpenCalendlyModel, showAvgActivityModal, setShowAvgActivityModal, contentUpdateCount, setContentUpdateCount
      }}
    >
      {children}
    </Context.Provider>
  );
};
