import React from "react";
import { Link } from "react-router-dom";

const ReducePain = ({ offer, paymentHanded }) => {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h1 className="blue_text fw-bold text-center mb-3">
              Feel Better and Move Better
            </h1>
            <p className="home_subtext text-center">
              Learn how to relax your muscles, improve your mobility, and
              strengthen your body.
            </p>
          </div>
          <div className="col-sm-6">
            <ul className="why-us">
              <li>
                <i className="fas fa-check-circle" />
                Incorporate stretching and quick workouts into your daily
                routine.
              </li>
              <li>
                <i className="fas fa-check-circle" />
                Keep your joints healthy, and moving well.
              </li>
            </ul>
          </div>
          <div className="col-sm-6">
            <ul className="why-us">
              <li>
                <i className="fas fa-check-circle" />
                Set up your desk ergonomically to help posture and avoid pain.
              </li>
              <li>
                <i className="fas fa-check-circle" />
                Learn how to self manage and keep going
              </li>
            </ul>
          </div>
          <div className="col-sm-12 mt-4">
            <h1 className="blue_text fw-bold text-center mb-3">
              Yes, It's Really This Simple!
            </h1>
            <p className="home_subtext text-center">
              Stop struggling and learn how to have a more comfortable,
              enjoyable day.
            </p>

            <div className="row">
              <div className="col-md-12 text-center">
                {paymentHanded ? (
                  <Link
                    onClick={() =>
                      paymentHanded(
                        offer.createdFor === "physical-therapists"
                          ? offer?.year?.stripePriceId
                          : offer?.year?.stripePlanId
                      )
                    }
                    className="book1-btn"
                  >
                    YES, I WANT TO FEEL BETTER
                  </Link>
                ) : (
                  <Link to="/contact" className="book1-btn">
                    YES, I WANT TO FEEL BETTER
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReducePain;
