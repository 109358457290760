import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import moment from "moment";

import defaultImg from "./../../assets/images/defaultImg.jpg";
import { Context } from "../../context/Context";
import useApiAxios from "../../api/useApiAxios";
import ImageComponent from "../media/ImageComponent";
import DeleteSubscriptionModel from "../Profile/DeleteSubscriptionModal";

const Profile = () => {
  const { userData } = useContext(Context);
  const { patchUserData, uploadImage, homeApi, handleSubscriptionCancel } =
    useApiAxios();

  const [image, setImage] = useState("");

  const [imageUrl, setImageUrl] = useState("");
  const [deleteSubscriptionModal, setDeleteSubscriptionModal] = useState(false);
  const [deleteSubscriptionData, setDeleteSubscriptionData] = useState(null);
  const openDeleteSubscriptionModel = (data) => {
    setDeleteSubscriptionData(data);
    setDeleteSubscriptionModal(true);
  };
  const closeDeleteSubscriptionModel = () => {
    setDeleteSubscriptionModal(false);
    setDeleteSubscriptionData(null);
  };

  const planExpireDate = moment(userData?.plainExpire).format("MM/DD/YYYY");

  const fun = async () => {};

  const deleteSubscriptionHandler = async (data) => {
    await handleSubscriptionCancel(data);
    await fun();
    closeDeleteSubscriptionModel();
  };

  return userData ? (
    <div
      style={{
        marginTop: "8rem",
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "1200px",
      }}
    >
      <div className="container-fluid">
        <div className="row d-flex">
          <div className="col-sm-5">
            <h1 className="page-title">Update User Profile</h1>
          </div>
        </div>
        <div className="row align-items-stretch">
          <div className="col-xxl-12 mb-3 order-1 order-xxl-1">
            <div className="card customer-sf h-100">
              <div className="p-30">
                <Formik
                  initialValues={{
                    name: userData?.name || "",
                    dateOfBirth: moment(userData?.dateOfBirth).format(
                      "YYYY-MM-DD"
                    ),
                    gender: userData?.gender || "",
                    address: userData?.address || "",
                    phone: userData?.phone || "",
                    profileImage: userData?.profileImage || "",
                  }}
                  validationSchema={Yup.object({
                    name: Yup.string().required("Required"),
                    dateOfBirth: Yup.date().required("Required"),
                    gender: Yup.string().required("Required"),
                    address: Yup.string().required("Required"),
                    phone: Yup.string().required("Required"),
                  })}
                  onSubmit={async (values, { setSubmitting }) => {
                    try {
                      let imageUrl = null;
                      if (image) {
                        // Upload image and get image URL
                        imageUrl = await uploadImage(image);
                        // Set the uploaded image URL
                        setImageUrl(imageUrl);
                      }
                      // Call patchUserData with updated user data including the image URL
                      await patchUserData({ ...values, imageUrl });
                      await homeApi();
                    } catch (error) {
                      console.error("Error updating user data:", error);
                    }
                    setSubmitting(false);
                  }}
                >
                  <Form>
                    <div className="row mt-4">
                      <div className="col-md-6 col-xl-6">
                        <div className="row mb-3 form-fieldc">
                          <label className="col-sm-4 col-form-label">
                            Name
                          </label>
                          <div className="col-sm-8">
                            <Field
                              type="text"
                              name="name"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-xl-6">
                        <div className="row mb-3 form-fieldc">
                          <label className="col-sm-4 col-form-label">
                            Date of Birth
                          </label>
                          <div className="col-sm-8">
                            <Field
                              type="date"
                              name="dateOfBirth"
                              max="2006-07-01" // Set the minimum date here
                              className="form-control"
                            />

                            <ErrorMessage
                              name="dateOfBirth"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-xl-6">
                        <div className="row mb-3 form-fieldc">
                          <label className="col-sm-4 col-form-label">
                            Gender
                          </label>
                          <div className="col-sm-8">
                            <Field
                              as="select"
                              name="gender"
                              className="form-select"
                            >
                              <option value="">Select Gender</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Other">Other</option>
                            </Field>
                            <ErrorMessage
                              name="gender"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-xl-6">
                        <div className="row mb-3 form-fieldc">
                          <label className="col-sm-4 col-form-label">
                            Address
                          </label>
                          <div className="col-sm-8">
                            <Field
                              type="text"
                              name="address"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="address"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-xl-6">
                        <div className="row mb-3 form-fieldc">
                          <label className="col-sm-4 col-form-label">
                            Phone
                          </label>
                          <div className="col-sm-8">
                            <Field
                              type="text"
                              name="phone"
                              className="form-control"
                              maxLength={12}
                            />
                            <ErrorMessage
                              name="phone"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-xl-6">
                        {userData?.subscription ? (
                          <div className="row mb-3 form-fieldc">
                            <label className="col-sm-4 col-form-label">
                              Your Subscription will be expired on{" "}
                              {planExpireDate}
                            </label>
                            <div className="col-sm-8">
                              <button
                                type="button"
                                disabled={
                                  userData?.subscription.status === "active"
                                    ? false
                                    : true
                                }
                                className="btn addsubmit-btn green-btn mb-3"
                                onClick={() => openDeleteSubscriptionModel()}
                              >
                                Cancel Subscription
                              </button>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-6 col-lg-12 col-xl-6">
                        {/* Profile Picture field */}
                        <div className="row mb-3 form-fieldc">
                          <label className="col-sm-4 col-form-label">
                            Profile Picture
                          </label>
                          <div className="col-sm-8">
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) => setImage(e.target.files[0])}
                            />
                            {image && (
                              <img
                                src={URL.createObjectURL(image)}
                                alt="Selected"
                                style={{
                                  marginTop: "1rem",
                                  width: "200px",
                                  height: "200px",
                                  objectFit: "cover",
                                }}
                              />
                            )}
                            {!image && userData.profileImage && (
                              <ImageComponent
                                className="profileImage_user"
                                src={`${userData.profileImage}`}
                              />
                            )}
                            {!image && !userData.profileImage && (
                              <img
                                src={defaultImg}
                                alt="Default"
                                style={{
                                  marginTop: "1rem",
                                  width: "200px",
                                  height: "200px",
                                  objectFit: "cover",
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-12 col-xl-12">
                        <div className="mb-3 form-fieldc d-flex justify-content-start align-items-center">
                          <div className="d-flex gap-3">
                            <button
                              type="submit"
                              className="btn addsubmit-btn green-btn mb-3"
                            >
                              Update
                            </button>
                            <Link
                              to={"/"}
                              className="btn addsubmit-btn grey-btn mb-3"
                            >
                              Cancel
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteSubscriptionModel
        show={deleteSubscriptionModal}
        handleClose={closeDeleteSubscriptionModel}
        data={deleteSubscriptionData}
        deleteFunction={deleteSubscriptionHandler}
      />
    </div>
  ) : (
    <></>
  );
};

export default Profile;
