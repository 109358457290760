import React from "react";


import { Link} from "react-router-dom";


const Registered = () => {
  return (
    <>
      <h1>You Are All ready login </h1>
      <Link to={"/"} className="btn virt_btn green">
        Go back
      </Link>
    </>
  );
};
export default Registered;
