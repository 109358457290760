import { useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Context } from "../context/Context";

const useApiAxios = () => {
  axios.defaults.headers.common["Authorization"] =
    localStorage.getItem("bearer");
  axios.defaults.headers.common["Content-Type"] = "application/json";

  // You can also set headers for specific request methods
  axios.defaults.headers.get["Accept"] = "application/json";

  const {
    setUserData,
    setIsAuthorized,
    setIsLoading,
    openInfoSnackbar,
    openErrorSnackbar,
    updateCount,
    setUpdateCount,
    setContent,
    setShowPainModal,
    setShowSatisfactionModal,
    setShowAvgActivityModal,
    setContentUpdateCount,
  } = useContext(Context);
  const ENDPOINT = process.env.REACT_APP_ENDPOINT;
  const LOGIN = "/";
  const REGISTER = "/create/user";
  const HOME = "/";
  const LOGOUT = "/logout";
  const FORGET_PASSWORD = "/forget/password";
  const CHANGE_FORGET_PASSWORD = "/change/forget/password";
  const PRICE = "/priceList";
  const CREATE_SUBSCRIPTION = "/create/subscription";
  const UPDATE_SUBSCRIPTION = "/update/subscription";
  const CONTACT = "/create/Contact";
  const EMAIL_SUBSCRIBE = "/email/subscribe";
  const GET_WHITE_PAPER = "/create/paper";
  const GET_OFFER = "/get/offer";
  const CREATE_INTENT_SUBSCRIPTION = "/create/intent/subscription";
  const RE_PAYMENT_SUBSCRIPTION = "/re/subscription";
  const RE_PAYMENT_SUBSCRIPTION_COMPANY = "/re/subscription/company";
  const CREATE_ACTIVITIES_LOGS = "/create/activities/logs";
  const UPDATE_PROFILE = "/update/user/profile";
  const UPLOAD_IMAGE = "/upload/user/profile/image";
  const CREATE_DYNAMIC_QUESTION = "/create/dynamic/question";
  const CREATE_ANSWER_DATA = "/create/answer/data";
  const GET_ANSWER_DATA = "/get/answer/data";
  const GET_ANSWER_DATA_PLAYLIST = "/get/answer/data/playlist";
  const CREATE_USER_QUESTION_ANSWER = "/create/user/question/answer";
  const NEXT_VIDEO_WATCH = "/next/video/watch";
  const END_PLAYLIST = "/end/playlist";
  const UPDATE_PASSWORD = "/profile/update/password";
  const WEBSITE_CONTENT = "/website/content";

  const CREATE_PAIN_LABEL = "/create/painLabel/data";
  const CREATE_AVG_FUNCTIONAL = "/create/avg/data";
  const CREATE_SATISFACTION_LABEL = "/create/customerSatisfaction/data";
  const CHECK_ADMIN_EMAIL = "/check/adminEmail";
  const CHECK_USER_EMAIL = "/check/userEmail";
  const CREATE_APPOINTMENT_LEAD = "/create/appointment/lead";
  const CANCEL_SUBSCRIPTION = "/cancel/subscription";
  const GET_COMPANY_BY_ID = "/company/by/id";

  const navigate = useNavigate();

  axios.interceptors.response.use(
    (response) => response, // If the response is successful, just return it
    async (error) => {
      if (error.response && error.response.status === 401) {
        openErrorSnackbar(error.response.message);
        setIsAuthorized(false);
        localStorage.removeItem("bearer");
        await homeApi();
        navigate("/login");
      }
      return Promise.reject(error);
    }
  );
  const loginApi = async ({ password, email, loggedIn }) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${LOGIN}`, {
        password,
        email,
        loggedIn,
      });
      localStorage.setItem("bearer", res.data.data.token);
      setShowPainModal(res.data.data.showPainModal);
      setShowSatisfactionModal(res.data.data.showSatisfactionModal);
      setShowAvgActivityModal(
        res.data.data.avgActivityLevelOlderThanOneMonth &&
          res.data.data.user.activities.length !== 0
          ? true
          : false
      );
      setUpdateCount(updateCount + 1);
      setUserData(res.data.data.user);
      setIsAuthorized(true);

      if (
        (res.data?.data?.user?.subscription?.status === "active" ||
          res?.data?.data?.user?.type === "company") &&
        !res.data?.data?.user?.currentUserAnswer &&
        res.data?.data?.user?.address
      ) {
        navigate("/intro");
      } else if (
        res.data?.data?.user?.subscription?.status === "inactive" &&
        res.data.data.user.type === "physicalTherapist"
      ) {
        navigate("/subscribe");
      } else if (
        (res?.data?.data?.user?.subscription?.status === "active" ||
          res?.data?.data?.user?.type === "company") &&
        res?.data?.data?.user?.currentUserAnswer &&
        res?.data?.data?.user?.address
      ) {
        if (res?.data?.data?.user?.currentUserAnswer?.completed) {
          navigate(
            `/question/playlist/${res?.data?.data?.user?.currentUserAnswer._id}`
          );
        } else {
          navigate(
            `/question/d-pain/${res.data?.data?.user?.currentUserAnswer._id} `
          );
        }
      } else if (
        (res?.data?.data?.user?.subscription ||
          res?.data?.data?.user?.type === "company") &&
        !res?.data?.data?.user?.address
      ) {
        navigate("/profile");
      } else {
        navigate("/subscribe");
      }
      setIsLoading(false);
    } catch (error) {
      openErrorSnackbar(error.response.data.message);
      setIsLoading(false);
    }
  };

  const forgetPasswordApi = async ({ email, loggedIn }) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${FORGET_PASSWORD}`, { email });
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data.data };
    } catch (error) {
      openErrorSnackbar(error.response.data.message);
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const changePasswordOTP = async ({
    otp,
    password,
    passwordConfirm,
    email,
  }) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${CHANGE_FORGET_PASSWORD}`, {
        otp,
        password,
        passwordConfirm,
        email,
      });
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      navigate("/login");
    } catch (error) {
      openErrorSnackbar(error.response.data.message);
      setIsLoading(false);
    }
  };

  const registerApi = async ({
    name,
    phone,
    gender,
    email,
    password,
    passwordConfirm,
    dateOfBirth,
    offer,
  }) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${REGISTER}`, {
        name,
        phone,
        gender,
        email,
        password,
        passwordConfirm,
        dateOfBirth,
        offer,
      });
      setUserData(res.data.data.user);
      localStorage.setItem("bearer", res.data.data.token);
      setIsAuthorized(true);

      navigate("/subscribe");
      setIsLoading(false);
    } catch (error) {
      openErrorSnackbar(error.response.data.message);
      setIsLoading(false);
    }
  };
  const homeApi = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${HOME}`);
      setIsAuthorized(true);
      setUserData(res.data.user);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const logOutApi = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${LOGOUT}`);
      localStorage.removeItem("bearer");
      setUserData({});
      setIsAuthorized(false);

      navigate("/");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      navigate("/");
    }
  };

  const getPriceListApi = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${PRICE}`);

      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: null };
    }
  };

  const createSubscription = async (priceId, offerId) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${CREATE_SUBSCRIPTION}`, {
        priceId,
        offerId,
      });

      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      return { error: true, data: null };
    }
  };

  const updateSubscription = async (session_id) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${UPDATE_SUBSCRIPTION}`, {
        session_id,
      });

      setUpdateCount(updateCount + 1);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      return { error: true, data: null };
    }
  };
  const contactApi = async ({
    firstName,
    lastName,
    phone,
    email,
    query,
    inquiryAbout,
    organization,
    iAmAsA,
  }) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${CONTACT}`, {
        firstName,
        lastName,
        phone,
        email,
        query,
        inquiryAbout,
        organization,
        iAmAsA,
      });
      navigate("/");
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
    } catch (error) {
      openErrorSnackbar(error.response.data.message);
      setIsLoading(false);
    }
  };

  const createEmailSubscribe = async ({ email }) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${EMAIL_SUBSCRIBE}`, { email });

      setIsLoading(false);
      openInfoSnackbar(res.data.message);
    } catch (error) {
      openErrorSnackbar(error.response.data.message);
      setIsLoading(false);
    }
  };

  const getWhitePaper = async ({
    firstName,
    lastName,
    email,
    phone,
    organization,
  }) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${GET_WHITE_PAPER}`, {
        firstName,
        lastName,
        email,
        phone,
        organization,
      });
      // Handle success response
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
    } catch (error) {
      // Handle error response
      openErrorSnackbar(error.response.data.message);
      setIsLoading(false);
    }
  };

  const createIntentSubscriptionApi = async ({
    planId,
    paymentId,
    email,
    name,
    offerId,
    createdFor,
  }) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${CREATE_INTENT_SUBSCRIPTION}`, {
        planId,
        paymentId,
        email,
        name,
        offerId,
        createdFor,
      });
      // Handle success response
      setIsLoading(false);

      openInfoSnackbar(res.data.message);
      navigate(`/subscription/success?for=${createdFor}`);
      return { error: false, data: res.data.data };
    } catch (error) {
      // Handle error response
      openErrorSnackbar(error.response.data.message);
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const getOfferById = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${GET_OFFER}?id=${id}`);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const patchUserData = async ({
    name,
    dateOfBirth,
    email,
    gender,
    address,
    phone,
  }) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${UPDATE_PROFILE}`, {
        name,
        dateOfBirth,
        email,
        gender,
        address,
        phone,
      });

      setIsLoading(false);
      openInfoSnackbar("User data updated successfully!");
      navigate("/intro");
    } catch (error) {
      openErrorSnackbar(error.response.data.message);
      setIsLoading(false);
    }
  };
  const uploadImage = async (image) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("image", image);

      const res = await axios.post(
        `${ENDPOINT}${UPLOAD_IMAGE}`, // Appending courseId to the URL
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Assuming the response contains the URL of the uploaded image
      const imageUrl = res.data.imageUrl;

      openInfoSnackbar(res.data.message);
      setIsLoading(false);

      return imageUrl;
    } catch (error) {
      openErrorSnackbar(error.response.data.message);
      setIsLoading(false);

      throw error; // Rethrow the error to handle it in the component
    }
  };

  const createActivitiesLogsApi = async (data) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${ENDPOINT}${CREATE_ACTIVITIES_LOGS}`,
        data
      );

      openInfoSnackbar(res.data.message);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: null };
    }
  };

  const createDynamicUserQuestion = async (data) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${ENDPOINT}${CREATE_DYNAMIC_QUESTION}`,
        data
      );

      openInfoSnackbar(res.data.message);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: null };
    }
  };

  const createDynamicUserQuestionApi = async (data) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${CREATE_ANSWER_DATA}`, data);

      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      openInfoSnackbar("SomeThing went wrong");
      setIsLoading(false);
      return { error: true, data: null };
    }
  };

  const getDynamicUserQuestionApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${GET_ANSWER_DATA}?id=${id}`);

      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      openInfoSnackbar("SomeThing went wrong");
      setIsLoading(false);
      return { error: true, data: null };
    }
  };

  const getDynamicUserQuestionPlaylistApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${ENDPOINT}${GET_ANSWER_DATA_PLAYLIST}?id=${id}`
      );

      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      openInfoSnackbar("SomeThing went wrong");
      setIsLoading(false);
      return { error: true, data: null };
    }
  };

  const createUserQuestionAnswerApi = async (data) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${ENDPOINT}${CREATE_USER_QUESTION_ANSWER}`,
        data
      );

      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      openInfoSnackbar("SomeThing went wrong");
      setIsLoading(false);
      return { error: true, data: null };
    }
  };
  const nextVideoWatchApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.put(`${ENDPOINT}${NEXT_VIDEO_WATCH}?id=${id}`);

      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      openInfoSnackbar("SomeThing went wrong");
      setIsLoading(false);
      return { error: true, data: null };
    }
  };
  const removePlaylistApi = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${END_PLAYLIST}`);
      setIsAuthorized(true);
      await homeApi();
      navigate("/question/painQuestion");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const changePasswordApi = async (data) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${UPDATE_PASSWORD}`, data);
      setIsAuthorized(true);

      navigate("/");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
    }
  };

  const getContentApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${WEBSITE_CONTENT}?id=${id}`);
      setContent(res.data.data);

      setIsLoading(false);
      setContentUpdateCount((pre) => (pre === 0 ? 1 : 1));
      return { error: false, data: res.data };
    } catch (error) {
      openInfoSnackbar("SomeThing went wrong");
      setIsLoading(false);
      return { error: true, data: null };
    }
  };

  const createPainLabelApi = async (data) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${CREATE_PAIN_LABEL}`, {
        painLabel: data,
      });
      setIsLoading(false);

      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };

  const createFunctionalLabelApi = async (data) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${CREATE_AVG_FUNCTIONAL}`, data);
      setIsLoading(false);

      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };

  const createSatisfactionLabelApi = async (data) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${CREATE_SATISFACTION_LABEL}`, {
        customerSatisfaction: data,
      });
      setIsLoading(false);

      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };

  const checkAdminEmailApi = async ({ email }) => {
    try {
      const res = await axios.post(`${ENDPOINT}${CHECK_ADMIN_EMAIL}`, {
        email,
      });
      return { error: false, data: res.data.message };
    } catch (error) {
      return { error: true, data: error.response.data.message };
    }
  };

  const checkUserEmailApi = async ({ email }) => {
    try {
      const res = await axios.post(`${ENDPOINT}${CHECK_USER_EMAIL}`, {
        email,
      });
      return { error: false, data: res.data.message };
    } catch (error) {
      return { error: true, data: error.response.data.message };
    }
  };

  const createAppointmentLeadApi = async () => {
    try {
      const res = await axios.get(`${ENDPOINT}${CREATE_APPOINTMENT_LEAD}`, {});
      return { error: false, data: res.data.message };
    } catch (error) {
      return { error: true, data: error.response.data.message };
    }
  };

  const getImageApi = async (key) => {
    try {
      const res = await axios.get(`${ENDPOINT}/image/${key}`);

      return { error: false, data: res.data.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error?.response?.data };
    }
  };

  const handleSubscriptionCancel = async (_id) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${ENDPOINT}${CANCEL_SUBSCRIPTION}?id=${_id}`
      );
      openInfoSnackbar(res.data.message);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(
        error.response?.data?.message || "Failed to cancel subscription"
      );
      return { error: true, data: error.response?.data };
    }
  };
  const reSubscriptionApi = async ({
    planId,
    paymentId,
    email,
    name,
    offerId,
    createdFor,
  }) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${RE_PAYMENT_SUBSCRIPTION}`, {
        planId,
        paymentId,
        email,
        name,
        offerId,
        createdFor,
      });

      setIsLoading(false);

      openInfoSnackbar(res.data.message);
      navigate(`/subscription/success/re/subscribe?for=${createdFor}`);
      return { error: false, data: res.data.data };
    } catch (error) {
      // Handle error response
      openErrorSnackbar(error.response.data.message);
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };
  const reSubscriptionCompanyApi = async ({
    planId,
    paymentId,
    email,
    name,
    offerId,
    createdFor,
    companyId,
  }) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${ENDPOINT}${RE_PAYMENT_SUBSCRIPTION_COMPANY}`,
        {
          planId,
          paymentId,
          email,
          name,
          offerId,
          createdFor,
          companyId,
        }
      );
      // Handle success response
      setIsLoading(false);

      openInfoSnackbar(res.data.message);
      navigate(`/subscription/success/re/subscribe?for=${createdFor}`);
      return { error: false, data: res.data.data };
    } catch (error) {
      // Handle error response
      openErrorSnackbar(error.response.data.message);
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };
  const getCompany = async (id, sort, search, tag) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${GET_COMPANY_BY_ID}?id=${id}`);
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  return {
    homeApi,
    loginApi,
    registerApi,
    logOutApi,
    changePasswordOTP,
    forgetPasswordApi,
    getPriceListApi,
    createSubscription,
    updateSubscription,
    contactApi,
    createEmailSubscribe,
    getWhitePaper,
    getOfferById,
    createIntentSubscriptionApi,
    createActivitiesLogsApi,
    patchUserData,
    uploadImage,
    createDynamicUserQuestion,
    createDynamicUserQuestionApi,
    getDynamicUserQuestionApi,
    createUserQuestionAnswerApi,
    getDynamicUserQuestionPlaylistApi,
    nextVideoWatchApi,
    removePlaylistApi,
    changePasswordApi,
    getContentApi,
    createPainLabelApi,
    createSatisfactionLabelApi,
    checkAdminEmailApi,
    checkUserEmailApi,
    createAppointmentLeadApi,
    getImageApi,
    createFunctionalLabelApi,
    handleSubscriptionCancel,
    reSubscriptionApi,
    getCompany,
    reSubscriptionCompanyApi,
  };
};

export default useApiAxios;
