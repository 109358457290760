import React from 'react'
import Image1 from "../../assets/video/Cervical-Retractionjpg-257.jpg"
import Image2 from "../../assets/video/Cervical-retraction-in-pronejpg-257.jpg"
import Image3 from "../../assets/video/Cervical-retraction-in-supinejpg-257.jpg"
const VideoFooter = () => {
  return (
    <div className="container virt-videos mb-4 mb-xl-5 pt-5 pb-4 pb-xl-5">
    <div className="row py-4 py-xl-5 justify-content-center">
      <div className="col-xl-12 mt-5">
        <h1 className="home_title mb-3">Instructional Videos </h1>
        <p className="home_subtext"></p>
        <div className="row g-3">
          <div className="col-md-4">
            <nav id="myTab" className="nav nav-pills flex-column">
              <a
                href="#category_tab1"
                data-bs-toggle="pill"
                className="active nav-link"
              >
                {" "}
                Cervical
              </a>
              <a href="#category_tab2" data-bs-toggle="pill" className="nav-link">
                {" "}
                Thoracic
              </a>
              <a href="#category_tab3" data-bs-toggle="pill" className="nav-link">
                {" "}
                Lumbar
              </a>
              <a href="#category_tab4" data-bs-toggle="pill" className="nav-link">
                {" "}
                Upper Extremities
              </a>
              <a href="#category_tab5" data-bs-toggle="pill" className="nav-link">
                {" "}
                Lower Extremities
              </a>
              <a href="#category_tab6" data-bs-toggle="pill" className="nav-link">
                {" "}
                Sitting with Low Back Support
              </a>
              <a href="#category_tab7" data-bs-toggle="pill" className="nav-link">
                {" "}
                Posture Correction / Slouch-Overcorrect
              </a>
            </nav>
          </div>
          <div className="col-md-8 tab-content">
            <article className="tab-pane fade show active" id="category_tab1">
              <h2 className="mb-3">Cervical</h2>
              <div className="row">
                <div className="col-6">
                  <div className="card video_card ">
                    <div className="card-body">
                      <a href="#">
                        <img
                          decoding="async"
                          src={Image1}
                          alt=""
                          width={257}
                          height={145}
                        />
                      </a>
                      <p className="card-text mt-4">
                        Cervical Retraction (sitting)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="card video_card ">
                    <div className="card-body">
                      <a href="#">
                        <img
                          decoding="async"
                          src={Image2}
                          alt=""
                          width={257}
                          height={145}
                        />
                      </a>
                      <p className="card-text mt-4">
                        Cervical Retraction (supine)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="card video_card ">
                    <div className="card-body">
                      <a href="#">
                        <img
                          decoding="async"
                          src={Image3}
                          alt=""
                          width={257}
                          height={145}
                        />
                      </a>
                      <p className="card-text mt-4">
                        Cervical Retraction (prone)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </article>
            <article className="tab-pane fade" id="category_tab2">
              <h2 className="mb-3"> Thoracic</h2>
              <div className="row">
                <div className="col-6">
                  <div className="card video_card ">
                    <div className="card-body">
                      <a href="#">
                        <img
                          decoding="async"
                          src={Image1}
                          alt=""
                          width={257}
                          height={145}
                        />
                      </a>
                      <p className="card-text mt-4">
                        Cervical Retraction (sitting)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="card video_card ">
                    <div className="card-body">
                      <a href="#">
                        <img
                          decoding="async"
                          src={Image2}
                          alt=""
                          width={257}
                          height={145}
                        />
                      </a>
                      <p className="card-text mt-4">
                        Cervical Retraction (supine)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="card video_card ">
                    <div className="card-body">
                      <a href="#">
                        <img
                          decoding="async"
                          src={Image3}
                          alt=""
                          width={257}
                          height={145}
                        />
                      </a>
                      <p className="card-text mt-4">
                        Cervical Retraction (prone)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="card video_card ">
                    <div className="card-body">
                      <a href="#">
                        <img
                          decoding="async"
                          src={Image1}
                          alt=""
                          width={257}
                          height={145}
                        />
                      </a>
                      <p className="card-text mt-4">
                        Cervical Retraction (sitting)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="card video_card ">
                    <div className="card-body">
                      <a href="#">
                        <img
                          decoding="async"
                          src={Image2}
                          alt=""
                          width={257}
                          height={145}
                        />
                      </a>
                      <p className="card-text mt-4">
                        Cervical Retraction (supine)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </article>
            <article className="tab-pane fade" id="category_tab3">
              <h2 className="mb-3">Lumbar</h2>
              <div className="row">
                <div className="col-6">
                  <div className="card video_card ">
                    <div className="card-body">
                      <a href="#">
                        <img
                          decoding="async"
                          src={Image1}
                          alt=""
                          width={257}
                          height={145}
                        />
                      </a>
                      <p className="card-text mt-4">
                        Cervical Retraction (sitting)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="card video_card ">
                    <div className="card-body">
                      <a href="#">
                        <img
                          decoding="async"
                          src={Image2}
                          alt=""
                          width={257}
                          height={145}
                        />
                      </a>
                      <p className="card-text mt-4">
                        Cervical Retraction (supine)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="card video_card ">
                    <div className="card-body">
                      <a href="#">
                        <img
                          decoding="async"
                          src={Image3}
                          alt=""
                          width={257}
                          height={145}
                        />
                      </a>
                      <p className="card-text mt-4">
                        Cervical Retraction (prone)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="card video_card ">
                    <div className="card-body">
                      <a href="#">
                        <img
                          decoding="async"
                          src={Image1}
                          alt=""
                          width={257}
                          height={145}
                        />
                      </a>
                      <p className="card-text mt-4">
                        Cervical Retraction (sitting)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="card video_card ">
                    <div className="card-body">
                      <a href="#">
                        <img
                          decoding="async"
                          src={Image2}
                          alt=""
                          width={257}
                          height={145}
                        />
                      </a>
                      <p className="card-text mt-4">
                        Cervical Retraction (supine)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </article>
            <article className="tab-pane fade" id="category_tab4">
              <h2 className="mb-3">Upper Extremities</h2>
              <div className="row">
                <div className="col-6">
                  <div className="card video_card ">
                    <div className="card-body">
                      <a href="#">
                        <img
                          decoding="async"
                          src={Image3}
                          alt=""
                          width={257}
                          height={145}
                        />
                      </a>
                      <p className="card-text mt-4">
                        Cervical Retraction (supine)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="card video_card ">
                    <div className="card-body">
                      <a href="#">
                        <img
                          decoding="async"
                          src={Image2}
                          alt=""
                          width={257}
                          height={145}
                        />
                      </a>
                      <p className="card-text mt-4">
                        Cervical Retraction (prone)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="card video_card ">
                    <div className="card-body">
                      <a href="#">
                        <img
                          decoding="async"
                          src={Image1}
                          alt=""
                          width={257}
                          height={145}
                        />
                      </a>
                      <p className="card-text mt-4">
                        Cervical Retraction (sitting)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="card video_card ">
                    <div className="card-body">
                      <a href="#">
                        <img
                          decoding="async"
                          src={Image3}
                          alt=""
                          width={257}
                          height={145}
                        />
                      </a>
                      <p className="card-text mt-4">
                        Cervical Retraction (supine)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </article>
            <article className="tab-pane fade" id="category_tab5">
              <h2 className="mb-3">Lower Extremities</h2>
              <div className="row">
                <div className="col-6">
                  <div className="card video_card ">
                    <div className="card-body">
                      <a href="#">
                        <img
                          decoding="async"
                          src={Image3}
                          alt=""
                          width={257}
                          height={145}
                        />
                      </a>
                      <p className="card-text mt-4">
                        Cervical Retraction (supine)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="card video_card ">
                    <div className="card-body">
                      <a href="#">
                        <img
                          decoding="async"
                          src={Image2}
                          alt=""
                          width={257}
                          height={145}
                        />
                      </a>
                      <p className="card-text mt-4">
                        Cervical Retraction (prone)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="card video_card ">
                    <div className="card-body">
                      <a href="#">
                        <img
                          decoding="async"
                          src={Image1}
                          alt=""
                          width={257}
                          height={145}
                        />
                      </a>
                      <p className="card-text mt-4">
                        Cervical Retraction (sitting)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="card video_card ">
                    <div className="card-body">
                      <a href="#">
                        <img
                          decoding="async"
                          src={Image3}
                          alt=""
                          width={257}
                          height={145}
                        />
                      </a>
                      <p className="card-text mt-4">
                        Cervical Retraction (supine)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </article>
            <article className="tab-pane fade" id="category_tab6">
              <h2 className="mb-3">Sitting with Low Back Support</h2>
              <div className="row">
                <div className="col-6">
                  <div className="card video_card ">
                    <div className="card-body">
                      <a href="#">
                        <img
                          decoding="async"
                          src={Image2}
                          alt=""
                          width={257}
                          height={145}
                        />
                      </a>
                      <p className="card-text mt-4">
                        Cervical Retraction (prone)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="card video_card ">
                    <div className="card-body">
                      <a href="#">
                        <img
                          decoding="async"
                          src={Image1}
                          alt=""
                          width={257}
                          height={145}
                        />
                      </a>
                      <p className="card-text mt-4">
                        Cervical Retraction (sitting)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="card video_card ">
                    <div className="card-body">
                      <a href="#">
                        <img
                          decoding="async"
                          src={Image3}
                          alt=""
                          width={257}
                          height={145}
                        />
                      </a>
                      <p className="card-text mt-4">
                        Cervical Retraction (supine)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </article>
            <article className="tab-pane fade" id="category_tab7">
              <h2 className="mb-3"> Posture Correction / Slouch-Overcorrect</h2>
              <div className="row">
                <div className="col-6">
                  <div className="card video_card ">
                    <div className="card-body">
                      <a href="#">
                        <img
                          decoding="async"
                          src={Image3}
                          alt=""
                          width={257}
                          height={145}
                        />
                      </a>
                      <p className="card-text mt-4">
                        Cervical Retraction (supine)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="card video_card ">
                    <div className="card-body">
                      <a href="#">
                        <img
                          decoding="async"
                          src={Image2}
                          alt=""
                          width={257}
                          height={145}
                        />
                      </a>
                      <p className="card-text mt-4">
                        Cervical Retraction (prone)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="card video_card ">
                    <div className="card-body">
                      <a href="#">
                        <img
                          decoding="async"
                          src={Image1}
                          alt=""
                          width={257}
                          height={145}
                        />
                      </a>
                      <p className="card-text mt-4">
                        Cervical Retraction (sitting)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="card video_card ">
                    <div className="card-body">
                      <a href="#">
                        <img
                          decoding="async"
                          src={Image3}
                          alt=""
                          width={257}
                          height={145}
                        />
                      </a>
                      <p className="card-text mt-4">
                        Cervical Retraction (supine)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>{" "}
        {/* row.// */}
        <p />
      </div>
    </div>
  </div>
  
  )
}

export default VideoFooter