import React, { useState } from "react";
import arrowRight from "../../assets/images/arrow-right.svg";
import loginLogo from "../../assets/images/hanna1.png";
import { useFormik } from "formik";
import * as Yup from "yup"; 
import PasswordResetForm from "./PasswordResetForm";
import useApiAxios from "../../api/useApiAxios";
import { Link } from "react-router-dom";

const ForgetPassword = () => {
    // Get current year
    const currentYear = new Date().getFullYear();
  const { changePasswordOTP, forgetPasswordApi } = useApiAxios();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [otpSent, setOtpSent] = useState(false); // State to track OTP sent status

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await forgetPasswordApi({ email: values.email });
        if (response.error) {
          setMessage(response?.data?.message || "Invalid email address");
        } else {
          setEmail(values.email);
          setMessage(`OTP Sent Successfully to ${values.email}!`);
          setOtpSent(true);
        }

      } catch (error) {

        setMessage(error.response?.data?.message || "Invalid email address");
      }
    },
  });

  return (
    <div className="login_page">
      <div className="row align-self-stretch h-100">
        {/*Col*/}
        <div className="col-xl-6 col-lg-6 align-self-stretch h-100 login_box_mob order-2 order-lg-1">
          <div className="h-100 px-0 px-lg-3 align-items-baseline login_box_outer scrollable-div d-flex align-items-start flex-column overyauto">
            <div className="row">
              <div className="col-12">
                <Link to="/login" className="login-back">
                  <img width={7} src={arrowRight} className="me-3" /> Back to
                  Login
                </Link>
              </div>
            </div>
            <div className="login-container m-auto">
              <div className="row">
                <h3 className="fw-bold login-title">Forgot Password</h3>
                {message && (
                  <div
                    className={`alert ${
                      message.includes("Successfully")
                        ? " alert-success py-1 px-2"
                        : "alert-danger"
                    }`}
                  >
                    {message}
                  </div>
                )}
                <form onSubmit={formik.handleSubmit}>
                  <div className="mb-2">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className={`form-control ${
                        formik.touched.email && formik.errors.email
                          ? "is-invalid"
                          : ""
                      }`}
                      id="email"
                      disabled={otpSent}
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="invalid-feedback">
                        {formik.errors.email}
                      </div>
                    ) : null}
                  </div>
                  <button
                    type="submit"
                    disabled={otpSent}
                    className="btn btn-block btn_login w-100 mb-2"
                  >
                    Send OTP
                  </button>
                </form>

                {otpSent && (
                  <PasswordResetForm
                    email={email}
                    changePasswordOTP={changePasswordOTP}
                  />
                )}
              </div>
            </div>
            <p className="copyright-text">© {currentYear} Hanna Health. All Rights Reserved</p>
          </div>
          {/*/Row*/}
        </div>
        {/*/Col*/}
        <div className="col-xl-6 col-lg-6 order-1 order-lg-2 pe-0">
          <div className="r-area">
            <div className="logo-r-area">
              <img src={loginLogo} className="d-block login_img" title="VIRT" />
            </div>
            <div className="logo-r-link">
              <ul>
              

                <li>
                  <Link to="/term">Terms of Use</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/*/Col*/}
      </div>
      {/*/Row*/}
   
    </div>
  );
};

export default ForgetPassword;
