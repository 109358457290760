import React, { useContext} from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { Context } from "../context/Context";
import useApiAxios from "../api/useApiAxios";

const ChangePassword = () => {
  const { userData } = useContext(Context);
  const { changePasswordApi } = useApiAxios();

  return userData ? (
    <div
      style={{
        marginTop: "8rem",
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "1200px",
      }}
    >
      <div className="container-fluid">
        <div className="row d-flex">
          <div className="col-sm-5">
            <h1 className="page-title">Password Change </h1>
          </div>
        </div>
        <div className="row align-items-stretch">
          <div className="col-xxl-12 mb-3 order-1 order-xxl-1">
            <div className="card customer-sf h-100">
              <div className="p-30">
                <Formik
                  initialValues={{
                    oldPassword: "",
                    newPassword: "",
                    passwordConfirm: "",
                  }}
                  validationSchema={Yup.object({
                    oldPassword: Yup.string().required("Required"),

                    newPassword: Yup.string()
                      .required("New Password is required")
                      .matches(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                        "Password must meet the requirements"
                      ),
                    passwordConfirm: Yup.string()
                      .required("Confirm New Password is required")
                      .oneOf(
                        [Yup.ref("newPassword"), null],
                        "Passwords must match"
                      ),
                  })}
                  onSubmit={async (values, { setSubmitting }) => {
                    try {
                      // Call patchUserData with updated user data including the image URL
                  
                      await changePasswordApi({ ...values });
                    } catch (error) {
                      console.error("Error updating user data:", error);
                    }
                    setSubmitting(false);
                  }}
                >
                  <Form>
                    <div className="row mt-4">
                      <div className="col-md-12 col-xl-12">
                        <div className="col-md-6 col-xl-6">
                          <div className="row mb-3 form-fieldc">
                            <label className="col-sm-4 col-form-label">
                              Old Password
                            </label>
                            <div className="col-sm-8">
                              <Field
                                type="password"
                                name="oldPassword"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="oldPassword"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-xl-12">
                        <div className="col-md-6 col-xl-6">
                          <div className="row mb-3 form-fieldc">
                            <label className="col-sm-4 col-form-label">
                              New Password
                            </label>
                            <div className="col-sm-8">
                              <Field
                                type="password"
                                name="newPassword"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="newPassword"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-xl-12">
                        <div className="col-md-6 col-xl-6">
                          <div className="row mb-3 form-fieldc">
                            <label className="col-sm-4 col-form-label">
                              Password Confirm
                            </label>
                            <div className="col-sm-8">
                              <Field
                                type="password"
                                name="passwordConfirm"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="passwordConfirm"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        </div>{" "}
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-6 col-lg-12 col-xl-6">
                        <div className="row mb-3 form-fieldc">
                          <label className="col-sm-4 col-form-label" />
                          <div className="col-sm-8">
                            <button
                              type="submit"
                              className="btn addsubmit-btn green-btn  me-3 mb-3"
                            >
                              Update
                            </button>
                            <Link
                              to={"/"}
                              className="btn addsubmit-btn grey-btn  mb-3"
                            >
                              Cancel
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ChangePassword;
