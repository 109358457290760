import React from "react";
import Question1 from "./Question1";

const PainQuestion = () => {
  return (
    <div className="container my-4 my-xl-5 pt-4 pt-xl-5">
      <h1>Question 1</h1>
      <Question1 />
    </div>
  );
};

export default PainQuestion;
