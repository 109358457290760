import React, { useState } from "react";
import body from "../../assets/images/Body-diagram-used-by-workers-to-indicate-their-musculoskeletal-symptoms.png";
import { Tooltip as ReactTooltip } from "react-tooltip";

const Question2 = () => {
  const [selectedBodyPart, setSelectedBodyPart] = useState(null);

  const areas = [
    { name: "Head", coords: [80, 40, 150, 170] },
    { name: "Torso", coords: [70, 40, 150, 170] },
  ];

  return (
    <div>
      <div className="body-image-container" style={{ position: "relative" }}>
        <img
          src={body}
          alt="Human Body"
          style={{ width: "50%", height: "auto" }} // Adjust width and height as needed
        />
        {areas.map((area) => (
          <div
            key={area.name}
            style={{
              position: "absolute",
              left: area.coords[0] / 2 + "%", // Adjust left position based on the image width
              top: area.coords[1] / 2 + "%", // Adjust top position based on the image height
              width: (area.coords[2] - area.coords[0]) / 2 + "%", // Adjust width based on the image width
              height: (area.coords[3] - area.coords[1]) / 2 + "%", // Adjust height based on the image height
              backgroundColor:
                selectedBodyPart === area.name ? "red" : "transparent",
            }}
            onClick={() => setSelectedBodyPart(area.name)}
            data-tip // Set the tooltip to show
            data-for={area.name} // Set a unique id for each area
          />
        ))}
        {areas.map((area) => (
          <ReactTooltip
            key={area.name}
            id={area.name}
            place="bottom" // Show the tooltip below the clicked area
            effect="solid"
          >
            {area.name}
          </ReactTooltip>
        ))}
      </div>
    </div>
  );
};

export default Question2;
