import React from "react";

const Canceled = () => {
  return (
    <div>
      <p>Canceled</p>
      <p>Canceled</p>
      <p>Canceled</p>
      <p>Canceled</p>
      <p>Canceled</p>
      <p>Canceled</p>
      <p>Canceled</p>
      <p>Canceled</p>
      <p>Canceled</p>
      <p>Canceled</p>
      <p>Canceled</p>
      <p>Canceled</p>
    </div>
  );
};

export default Canceled;
